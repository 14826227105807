<template lang="pug">
b-card.sill-card
  b-container(fluid)
    b-row
      b-col(sm="12" md="6" lg="2").mt-2
        p.d-sm-block.d-md-none.font-weight-bold Seleccione rango de fechas: 
        b-form-datepicker(
          v-model="filters.dateStart"
          placeholder="Rango fecha de Inicio"
          today-button reset-button close-button
          label-today-button="Hoy"
          label-reset-button="Limpiar"
          label-close-button="Cerrar"
          label-help="Puede usar las flechas para navegar."
          locale="es"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        )
      b-col(sm="12" md="6" lg="2").mt-2
        b-form-datepicker(
          v-model="filters.dateEnd"
          placeholder="Rango fecha de Fin"
          today-button reset-button close-button
          label-today-button="Hoy"
          label-reset-button="Limpiar"
          label-close-button="Cerrar"
          label-help="Puede usar las flechas para navegar."
          locale="es"
          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        )
      b-col(sm="12" md="6" lg="4").mt-2
        TagsFilter(
          v-model="filters.users"
          :options="users"
          labelKey="text"
          idKey="value"
          input-id="tech-selector"
          selectorLabel="Seleccionar técnicos"
        )
      b-col(sm="12" md="6" lg="2" class="text-right").mt-lg-2
        b-form-select(
          v-model="filters.tipo"
          :options="otTypeOptions"
        )
      b-col(sm="12" md="6" lg="2" class="text-right").mt-2
        b-button(
          variant="button"
          @click="handleExport"
          :disabled="loading"
        )
          <font-awesome-icon :icon="['fas', 'file-excel']" class="mr-2" />
          | Exportar Detalle


      //- b-col
      //-   b-button(
      //-     variant="button"
      //-     @click="getSummaryReport()"
      //-   ) Buscar
</template>

<script>
import { mapWritableState, mapActions, mapState } from 'pinia'
import { mapGetters, mapActions as mapActionsVuex } from 'vuex'
import { useSummaryReportStore } from '@/pinia/views/useSummaryReportStore';
import TagsFilter from '../../Shared/TagsFilter.vue'

export default {
  data() {
    return {
      loading: false,
      otTypeOptions: [
        { value: null, text: 'Todos los tipos' },
        { value: 1, text: 'Garantía' },
        { value: 4, text: 'Presupuesto' },
      ],
    }
  },
  components: {
    TagsFilter
  },
  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    ...mapState(useSummaryReportStore, ['techniciansParams']),
    ...mapWritableState(useSummaryReportStore, ['filters']),
    users() {
      return this.technicians.map((t) => {
        return {
          value: t.tecnico.id,
          text: `${t.tecnico.first_name} ${t.tecnico.last_name}`,
        }
      })
    },
  },
  watch: {
    'filters.dateStart': {
      handler() {
        this.listTechnicians({ params: this.techniciansParams })
      },
    },
    'filters.dateEnd': {
      handler() {
        this.listTechnicians({ params: this.techniciansParams })
      },
    },
    filters: {
      handler() {
        this.getSummaryReport()
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions(useSummaryReportStore, ['getSummaryReport', 'sendExcelReport']),
    ...mapActionsVuex('TechniciansStore', ['listTechnicians']),
    async handleExport() {
     try {
      this.loading = true;
      await this.sendExcelReport();
      this.$bvToast.toast('Se enviará el excel a tu correo electrónico', {
          variant: 'success',
          title: "Reporte Excel"
        })
      this.loading = false;
     } catch (error) {
      this.$bvToast.toast('Error al generar excel', {
          variant: 'danger',
          title: "Error"
        })
     }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
