<template lang="pug">
div
  b-container
    b-tabs(pills card lazy v-model= "tabSelected"
      @activate-tab="onTabChange"
    )
      b-tab(
        v-for="estado in estados"
        :key="estado.estado"
        :title="`${estado.estado} (${estado.cantidad})`"

      )
    b-row
      b-col
        b-table(
          :items="items"
          :fields="fields"
          hover
          resposive
          empty-text="Sin datos en historial"

        )
          template(#cell(index)="data")
            span {{ data.index + 1 }}
          template(#cell(cotizacion_pdf)="data")
            a(
              :href="informeUrl(data.item.cotizacion_pdf)"
              target="_blank",
              v-if="data.item.cotizacion_pdf"
            ) Descargar
          template(#cell(acciones)="data")
            OVActionsIndex(:ovId="data.item.id")
        b-pagination(
          v-model="currentPage",
          :total-rows="totalRows",
          align="right"
          prev-text="Ant.",
          next-text="Sig.",
          @change="handlePageChange"
        )
</template>

<script>
import { mapActions } from 'vuex'
import OVActionsIndex from './OVActions/OVActionsIndex.vue'
import { backUrl } from '../../../settings'

export default {
  name: 'ListOV',
  components: {
    OVActionsIndex,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 20,
      sortBy: null,
      sortDesc: false,
      items: [],
      estados: [],
      tabSelected: null,
      tabNameSelected: null,
      totalRows: 0,
      fields: [
        { key: 'id', label: 'ID', class: 'id-column' },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'apellido', label: 'Apellido', sortable: true },
        { key: 'correo_electronico', label: 'Correo Electronico', sortable: true },
        { key: 'telefono', label: 'Teléfono', sortable: true },
        { key: 'cotizacion_pdf', label: 'PDF', sortable: true },
        { key: 'acciones', label: 'Acciones', sortable: true, class: 'actions-column' },
      ],
    };
  },
  methods: {
    ...mapActions('OVStore', [
      'listOvAll',
      'getEstadosOV'
    ]),

    informeUrl(pdf) {
      return `${backUrl}${pdf}`
    },

    async getEstados() {
      const res = await this.getEstadosOV()
      // Agregar un estado para mostrar todos los OV


      this.estados.push({ estado: 'Todas', cantidad: res.length })
      this.estados = this.estados.concat(res)
    },

    async fetchData(page = 1) {
     try {
        const res = await this.listOvAll({ page, estado: this.tabNameSelected })
        this.items = res.results;
        this.totalRows = res.count;
      } catch (error) {
        console.log(error)
      }
    },
    onTabChange(tab) {
      this.currentPage = 1
      this.tabNameSelected = this.estados[tab].estado
      this.fetchData(this.currentPage)
    },
    handlePageChange(page) {
      this.fetchData(page)
    },
  },
  mounted() {
    this.fetchData()
    this.getEstados()
  },

}
</script>
<style lang="scss" scoped>
/* Personaliza el fondo de las pestañas activas */
::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link  {
  background-color: #F4C235;
  color: black;
  font-weight: bold;
  border-radius: 0.50rem;

}

::v-deep .nav-item a {
  color: #525252;
  font-weight: bold;
}

::v-deep .card-header-pills {
  background-color: #F5F6FA;
  border-radius: 0.50rem;
}
::v-deep .nav-item {
  margin: 6px;
}
::v-deep .card-header {
  background-color: #FFF;
}

::v-deep .tab-content > .active  {
  display: none;
}
::v-deep .b-table thead th {
  background-color: #DDDDE1;
  color: #525252;
  border: none;
}

::v-deep .b-table tbody td {
  border: none;
  background-color: #FFF;
}
::v-deep .b-table tbody tr td:first-child {
  font-weight: bold;
  color: black;
}
::v-deep .id-column {
  width: 60px;
}
::v-deep .actions-column {
  width: 200px;
}
.btn-action {
  background-color: #F4C235;
  color: black;
  border-radius: 0.50rem;
  border: none;
  &:hover {
    background-color: #F4C235;
    color: black;
    box-shadow: none;
  }
}
::v-deep .page-item.active .page-link {
  background-color: #F4C235;
  color: black;
}
::v-deep .page-link {
  color: black;
  border-color: black;
  border: 1px solid black;
  &:hover {
    color: black;
    border: 1px solid black;
    box-shadow: none;
  }
}
</style>
