import Vue from 'vue'
import Vuex from 'vuex'
import AuthenticationStore from './authentication/index'
import OT from './OT'
import EquipmentStore from './EquipmentStore'
import ClientStore from './OTClient'
import OTStore from './OTStore'
import TechniciansStore from './TechniciansStore'
import Diagnosticos from './OTDiagnose'
import Warehouse from './Warehouse'
import Notifications from './Notifications'
import Exceptions from './Exceptions'
import PreIngreso from './PreIngreso'
import Quotations from './Quotations'
import PartsStore from './PartsStore'
import OVStore from './OVStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    AuthenticationStore,
    OTStore,
    OT,
    EquipmentStore,
    ClientStore,
    TechniciansStore,
    Diagnosticos,
    Warehouse,
    Notifications,
    Exceptions,
    PreIngreso,
    Quotations,
    PartsStore,
    OVStore,
  },
})
