<template lang="pug">
  #DataClientQuotations
    b-row
      b-col(cols='12' md='6')
        b-form-group(class="rut" label-for="input-rut" :label-class="activeField === 'rut' ? 'active-label' : ''")
          template( #label ) RUT:
          b-form-input(
            placeholder="Ej: 76872144-0"
            id="input-rut"
            v-model="dataClient.rut"
            :state="$v.dataClient.rut.$dirty? !$v.dataClient.rut.$invalid : null"
            @input="$v.dataClient.rut.$touch()"
            @focus="activeField = 'rut'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.dataClient.rut.$dirty")
            b-form-invalid-feedback(:state="$v.dataClient.rut.required")
              | Campo requerido
    b-row
      b-col(cols='12' md='6')
        b-form-group(label-for="input-nombre" :label-class="activeField === 'nombre' ? 'active-label' : ''")
          template( #label ) Nombre:
          b-form-input(
            id="input-nombre"
            v-model="dataClient.nombre"
            placeholder="Ingresa tu Nombre"
            :state="$v.dataClient.nombre.$dirty? !$v.dataClient.nombre.$invalid : null"
            @input="$v.dataClient.nombre.$touch()"
            @focus="activeField = 'nombre'"
            @blur="activeField = ''"
            )
          .errors(v-if="$v.dataClient.apellido.$dirty")
            b-form-invalid-feedback(:state="$v.dataClient.nombre.required")
              | Campo requerido
      b-col(cols='12' md='6')
        b-form-group(label-for="input-apellido" :label-class="activeField === 'apellido' ? 'active-label' : ''")
          template( #label ) Apellido:
          b-form-input(
            id="input-apellido"
            v-model="dataClient.apellido"
            placeholder="Ingresa tu Apellido"
            :state="$v.dataClient.apellido.$dirty? !$v.dataClient.apellido.$invalid : null"
            @input="$v.dataClient.apellido.$touch()"
            @focus="activeField = 'apellido'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.dataClient.apellido.$dirty")
            b-form-invalid-feedback(:state="$v.dataClient.apellido.required")
              | Campo requerido
    b-row
      b-col(cols='12' md='4')
        b-form-group(label-for="input-region")
          template( #label ) Región:
          b-form-select(
              id="input-region"
              v-model="dataClient.region"
              :options="regionOptions"
              :state="$v.dataClient.region.$dirty? !$v.dataClient.region.$invalid : null"
          )
          .errors(v-if="$v.dataClient.region.$dirty")
            .error(v-if="!$v.dataClient.region.required")
              | Campo requerido
      b-col(cols='12' md='4')
        b-form-group(label-for="input-comuna")
          template( #label ) Comuna:
          b-form-select(
              id="input-comuna"
              v-model="dataClient.comuna"
              :options="comunaOptions"
              :state="$v.dataClient.region.$dirty? !$v.dataClient.comuna.$invalid : null"
          )
          .errors(v-if="$v.dataClient.comuna.$dirty")
            .error(v-if="!$v.dataClient.comuna.required")
              | Campo requerido
      b-col(cols='12' md='4')
        b-form-group(class="direccion" label-for="input-direccion" :label-class="activeField === 'direccion' ? 'active-label' : ''")
          template( #label ) Dirección:
          b-form-input(
            id="input-direccion"
            v-model="dataClient.direccion"
            :state="$v.dataClient.direccion.$dirty? !$v.dataClient.direccion.$invalid : null"
            @input="$v.dataClient.direccion.$touch()"
            @focus="activeField = 'direccion'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.dataClient.direccion.$dirty")
              b-form-invalid-feedback(:state="$v.dataClient.direccion.required")
                | Campo requerido
    b-row
      b-col(cols='12' md='6')
        b-form-group(class="telefono" label-for="input-telefono" :label-class="activeField === 'telefono' ? 'active-label' : ''")
          template( #label ) Teléfono:
          b-form-input(
            id="input-telefono"
            v-model="dataClient.telefono"
            placeholder="Ingresa un número de contacto"
            :state="$v.dataClient.telefono.$dirty? !$v.dataClient.telefono.$invalid : null"
            @input="$v.dataClient.telefono.$touch()"
            maxLength="9"
            @focus="activeField = 'telefono'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.dataClient.telefono.$dirty")
              b-form-invalid-feedback(:state="$v.dataClient.telefono.required")
                  | Campo requerido
              b-form-invalid-feedback(:state="$v.dataClient.telefono.numeric")
                  | Solo puedes ingresar numeros
              b-form-invalid-feedback(:state="$v.dataClient.telefono.minLength")
                  | Tienes que ingresar 8 digitos
      b-col(cols='12' md='6')
        b-form-group(label-for="input-email" :label-class="activeField === 'email' ? 'active-label' : ''")
          template( #label ) Email:
          b-form-input(
            id="input-email"
            v-model="dataClient.email"
            placeholder="Ingresa tu correo electrónico"
            :state="$v.dataClient.email.$dirty? !$v.dataClient.email.$invalid : null"
            @input="$v.dataClient.email.$touch()"
            @focus="activeField = 'email'"
            @blur="activeField = ''"
          )
          .errors(v-if="$v.dataClient.email.$dirty")
              b-form-invalid-feedback(:state="$v.dataClient.email.required")
                | Campo requerido
              b-form-invalid-feedback(:state="$v.dataClient.email.email")
                | No es una direccion de correo valida
  
    .d-flex.justify-content-end.mt-2
      b-button.form-btn(@click="onSubmit()" small variant="samtek-yellow") Siguiente Paso
  </template>
  
  <script>
  import axios from '@/plugins/axios'
  import { required, email, numeric, minLength } from 'vuelidate/lib/validators'
  import { mapGetters } from 'vuex';
  import validateRut from '../../../utils/validateRut'
  import validateEmail from '../../../utils/validateEmail'
  import * as cts from '../../OT/constants'
  
  
  
  export default {
    name: 'DataClientQuotations',
    props: ['setDataClient', 'returnClient'],
    data() {
      return {
        activeField: false,
        query: '',
        required: false,
        emailInvalid: true,
        dirty: false,
        cts,
        dataClient: {
          nombre: this.returnClient.nombre,
          apellido: this.returnClient.apellido,
          rut: this.returnClient.rut,
          email: this.returnClient.email,
          comuna: this.returnClient.comuna,
          telefono: this.returnClient.telefono,
          direccion: this.returnClient.direccion,
          region: this.returnClient.region,
        },
      }
    },
    validations: {
      dataClient: {
        rut: { required },
        nombre: { required },
        apellido: { required },
        email: { required, email },
        telefono: {
          required,
          numeric,
          minLength: minLength(8),
        },
        direccion: { required },
        comuna: { required },
        region: { required },
      },
    },
    created() {
      const {token} = this.$route.query
      if(token) {
        if(!this.dataClient.nombre || !this.dataClient.apellido || !this.dataClient.email || !this.dataClient.telefono) {
          this.getDataPreComplete(token)
        }
        
      }
    },
    methods: {
      onSubmit() {
        if (this.$v.$invalid) {
          this.$bvToast.toast('Complete todo los campos', {
            variant: 'danger',
          })
          this.$v.$touch()
        } else {
          this.setDataClient(this.dataClient)
        }
      },
      async getDataPreComplete(token) {
        try {
          const response = await axios.get(`/api/oportunidadventa/get-data-precompletado?token=${token}`)
          if(!response.data.data) return
          this.dataClient = {
            nombre: response.data.data.nombre,
            apellido: response.data.data.apellido,
            email: response.data.data.correo,
            telefono: response.data.data.telefono,
            token,
          }
        } catch (error) {
          const url = new URL(window.location.href);
          url.searchParams.delete('token'); 
          window.history.replaceState(null, '', url.toString());
        }
      },
      tagValidator(tag) {
        // Individual tag validator function
        const validate = validateEmail(tag)
        if (validate) {
          this.emailInvalid = true
          return validate
        }
        this.emailInvalid = false
        return validate
      },
      setNewClient() {
        try {
          const splitted = this.query.split('-')
          const loweredDigit = splitted[1].toString().toLowerCase()
          const rut = `${splitted[0]}-${loweredDigit}`
          this.dataClient.rut = rut
        } catch {
          this.$bvToast.toast('Ingrese un rut válido. Ej: 12345678-9', {
            variant: 'danger',
          })
        }
      },
    },
    computed: {
      rutIsValid() {
        return validateRut(this.dataClient.rut || '')
      },
      regionOptions() {
        const regions = this.regiones.map((region) => ({
          value: region.region,
          text: region.region,
        }))
        regions.unshift({ value: null, text: 'Selecione' })
        return regions
      },
      comunaOptions() {
        if (this.dataClient.region) {
          const region = this.regiones.find(
            (c) => c.region === this.dataClient.region
          )
          if (region && region.comunas) {
            return region.comunas.map((c) => ({
              value: c,
              text: c,
            }))
          }
        }
        return [
          {
            value: null,
            text: 'Selecione',
          },
        ]
      },
      ...mapGetters('ClientStore', ['regiones']),
    },
  }
  </script>
  
  <style lang="scss" scoped>
  @import '@/style/colors.scss';
  
  ::v-deep.form-card {
    box-shadow: 0 3px 6px 0 #00000029;
    opacity: 1;
    padding: 15px;
  
    .title {
      letter-spacing: 0px;
      color: #74788d;
      opacity: 1;
      font-weight: 600;
    }
  }
  .form-group::v-deep label {
    color: $samtek-grey;
  
    &.active-label {
      color: $samtek-black;
    }
  }
  #icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  .form-btn {
    font-weight: 600;
  }
  </style>
  