import axios from '../../plugins/axios'

export const listTechnicians = ({ commit }, { params } = {}) => {
  console.log('listTechnicians')
  const apiAddress = 'api/orden/tecnicos'
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
    params,
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_TECHNICIANS', { technicians: response.data.map(t => {
      return {
        ...t,
        tecnico: {
          ...t.tecnico, 
          first_name: t.tecnico.first_name.charAt(0).toUpperCase() + t.tecnico.first_name.slice(1),
          last_name: t.tecnico.last_name.charAt(0).toUpperCase() + t.tecnico.last_name.slice(1)
        }
      }
    })})
  })
}
