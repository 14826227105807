<template lang="pug">
b-row
  //- Search bar
  b-col( cols="12")
    b-input-group.with-icon
      b-input-group-prepend( is-text @click="searchedFilterOtherLink")
        b-spinner(v-if="loading" small)
        b-icon( v-else icon="search" font-scale="1")
      b-form-input(
        id="input-search"
        placeholder="Buscar OT por..."
        aria-label="número de OT a buscar"
        @keydown.enter="searchedFilterOtherLink"
        v-model="searchedFilter"
      ).search-input
      button.exit(
        @click="clearSearcher"
      )
        b-icon( icon="x" font-scale="1.5")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapWritableState } from 'pinia'
import { useDashboardStore } from '@/pinia/views/useDashboardStore'
import formatDate from '../../../utils/formatDate'

export default {
  name: 'GenericSearchFilter',
  props: [],
  components: {
    formatDate,
  },
  data() {
    return {
      loading: false,
      searchedFilter: null,
      isDropFilter: false,
      itsCloseFilter: false,
      itsAlreadyOT: false,
      aprobadas3x3: false,
      controller: null,
    }
  },
  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    ...mapGetters('OT', ['currentOts', 'defaultStates']),
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapWritableState(useDashboardStore, ['filters']),
  },
  methods: {
    ...mapActions('Exceptions', ['searchException']),
    ...mapActions('TechniciansStore', ['listTechnicians']),
    ...mapActions('OT', ['searchOT']),
    ...mapMutations('OT', ['SET_IS_SEARCHED']),
    // Funciones para busqueda
    async searchedFilterOtherLink() {
      if (!this.searchedFilter) {
        this.SET_IS_SEARCHED(false)
        this.searchingFilter(this.defaultStates)
        return;
      }
      await this.filters
      this.filters.tecnico = null
      if (this.$router.currentRoute.path !== '/listar-ordenes') {
        this.$router.push({
          name: 'Trabajo en curso',
          params: { dontSearch: true },
        })
       await this.searchingFilter()
      } else {
       await this.searchingFilter()
      }
    },
    searchingFilter(states = []) {
      this.loading = true
      if (this.controller) {
        this.controller.abort();
      }

      this.controller = new AbortController();
      const { signal } = this.controller;

      try {
        this.searchOT({
          query: this.searchedFilter,
          estados: states,
          fecha_ingreso: this.dateSearched,
          tecnico_asignado_id: this.tecnicoSearched,
          tipo_servicio: this.typeSearched,
          sucursal: this.sucursalSearched,
          signal,
        })
        this.itsAlreadyOT = true
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    },
    clearSearcher () {
      this.loading = true
      this.searchedFilter = null
      this.SET_IS_SEARCHED(false)
      this.searchingFilter(this.defaultStates)
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

#root-card {
  max-width: 100% !important;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}

.with-icon::v-deep {
  cursor: pointer;
  .input-group-text {
    border-right: none;
    background-color: white;
    border: 1px solid #001161;
  }
  .bi-search b-icon bi {
  }
  .form-control {
    background-color: white;
    border: 1px solid #001161;
    border-left: none;
    border-right: none;
  }
}
.exit {
  border: 1px solid #001161;
  border-left: none;
  border-start-end-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #35373f;
  background: white;
}
.clean {
  background-color: white;
  color: red;
  border: none;
  margin-left: 30px;
  margin-bottom: 10px;
}
.dropdown {
  padding: 0px;
  position: relative;
  margin: none;
}
.drop-button {
  background-color: white;
  color: #5b73e8;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border: 1px solid #5b73e8;
  border-radius: 0.25rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  line-height: 1.8;
}
.dropdown-content {
  padding: 15px;
  display: block;
  position: absolute;
  background-color: white;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(128, 124, 124, 0.2);
  top: 3rem;
  z-index: 7;
}
.client-form-inputs {
  color: #35373f;
  font-weight: 600;
}
::v-deep b-container {
  background-color: white;
}
.search-filter-container {
  height: 16.6vh;
  background-color: white;
  padding-top: 1.5%;
  padding-left: 2%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
}
.label-search {
  color: #74788d;
  font-weight: 700;
  margin-left: 2%;
  margin-top: 0.8em;
}
::v-deep b-button .clean-button {
  background-color: grey;
}
.dropdown-text {
  @include media-breakpoint-down(sm) {
    line-height: 1em;
  }
}

.search-input::placeholder {
  @include media-breakpoint-down(xs) {
    font-size: 15px;
    white-space: pre-line;
    position: relative;
  }
}

.backdrop {
  width: 100vw;
  height: 100vh;
  z-index: 6;
  background-color: #35373f;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
