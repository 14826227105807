<template lang="pug">
.main-wrapper
  .download-all-button-wrapper(v-if="galleryMode")
    b-btn.d-flex.justify-content-center.align-items-center(variant="outline-button" @click="downloadAllImages" :disabled="downloadingAll") Descargar Todas
      b-spinner.ml-2(v-if="downloadingAll" label='Cargando...' variant="primary" small)
  .loader(v-if="loading")
    b-spinner(label='Cargando...' variant="primary")
  b-row
    b-col(v-if="allFiles.length > 0")
      .thumbnails.mb-4(v-if="galleryMode")
        .thumbnail-item(v-for="file,index in files" :key="file.file_id")
          div(v-if="downloading.status && downloading.id === file.file_id " class="thumbnail-item-inner")
            b-spinner(label='Cargando...' variant="primary")
          .thumbnail-item-inner(v-else)
            b-icon.h3(v-if="file.file_type === 'pdf'" icon="file-pdf" variant="white")
            b-icon.h3(v-else-if="notAnImage(file.file_type)" icon="file-alt" variant="white")
            b-img(v-else-if="fileBlobUrls[index]" :src="fileBlobUrls[index]" thumbnail)
            b-icon.h3(v-else icon="images" variant="white")
          .flex-row.align-items-center.gap-1.justify-content-between
            .flex-row.align-items-center.gap-1
              svg-icon(type="mdi" :path="getThumbIcon(file).icon" :class="getThumbIcon(file).class")
              .thumb-file-name(v-b-popover.hover.top="file.name.split('/').pop()") {{ file.name.split('/').pop() }}
            b-dropdown.thumb-dropdown(size="sm" variant="outline-dark" no-caret)
              template(#button-content)
                b-icon(icon="three-dots-vertical")
              b-dropdown-item(@click="downloadItem(file)") Descargar
              b-dropdown-item(@click="confirmDelete = true; toDelete = file") Eliminar
          .thumb-type-label {{ getThumbLabel(file) }}

      b-table(
        v-if="!galleryMode || allFiles.some(f => f.isNew)"
        :items="galleryMode ? allFiles.filter(f => f.isNew) : allFiles"
        :fields="fields"
      )
        template(#cell(name)="data")
          label {{ data.item.name }}
        template(#cell(type)="{ item }")
          label(v-if="!item.isNew") {{ item.type }}
          b-form-select(v-else v-model="item.type" :options="attachmentTypes" class="size-small" @change="checkSelected(item)")
        template(#cell(actions)="{ item }")
          .d-flex.flex-row
            b-icon.h4(
              icon="arrow-down-square"
              cursor="pointer"
              @click="downloadItem(item)"
              style="color: black;"
              v-if="!item.isNew"
            )
            b-icon.h4.ml-2(
              icon="trash"
              cursor="pointer"
              @click="confirmDelete = true; toDelete = item"
              v-if="!item.isNew"
            )
            span.cursive(v-else) Pendiente de subir...

  b-row.d-flex.flex-column(v-if="canUploadMore")
    b-col.d-flex.flex-column
      label(class="client-form-inputs" ) Cargar Archivos:
      //- TODO: crear un componente de carga full-fledged con drag & drop
      label( :for="`input-file${uuid}`" ).file-upload
        .fileListWrapper
          span.labelDocumento(v-for="file in filesToUpload") {{ file.name }}
        span.labelDocumento(v-if="filesToUpload.length === 0") Haz click aquí para subir nuevos archivos
        span.fake-button Subir Archivos

      input.d-none(
        type="file"
        :id="`input-file${uuid}`"
        ref="file"
        accept=".pdf,.png,.jpg,.jpeg,.tiff,.gif"
        :multiple="!limitToOne"
        @change="handleFileUpload"
      )
      b-button(v-if="filesToUpload.length > 0" @click="addFile") Agregar

  b-row.d-flex.flex-column(v-if="otId && newFiles.length > 0")
    b-col.d-flex.flex-column.mb-5
      b-button.mt-2(@click="uploadNewFiles" variant="primary") Subir nuevos archivos

  //- Confirmar borrado
  b-modal(v-model="confirmDelete" title="¿Está seguro de eliminar archivo?" @ok="removeItem(toDelete)" )
    p(v-if="toDelete") {{ toDelete.file ? toDelete.name : toDelete.name.split('/').pop() }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { ARCHIVO_ADJUNTO_TIPO } from "@/utils/enums";
import UuidMixin from "@/mixins/UuidMixin";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiFilePdfBox, mdiImage } from '@mdi/js';
import JSZip from 'jszip';
import fileDownload from 'js-file-download';

export default {
  name: "UploadFileSection",
  components: { SvgIcon },
  props: ["otId", "fileNamePattern", "limitToOne", "attachmentType", "galleryMode"],
  mixins: [UuidMixin],
  data() {
    return {
      file: null,
      filesToUpload: [],
      newFiles: [],
      fields: [
        { key: "number", label: "#" },
        { key: "name", label: "Nombre" },
        { key: "type", label: "Tipo" },
        { key: "actions", label: "Acciones" },
      ],
      fileCount: 1,
      confirmDelete: false,
      toDelete: null,
      allFiles: [],
      newAttachmentType: this.attachmentType,
      loading: false,
      fileBlobUrls: [],
      downloadingAll: false,
      iconFilePdfBox: mdiFilePdfBox,
      iconImage: mdiImage,
      downloading: {
        id: null,
        status: false,
      }
    };
  },
  computed: {
    ...mapGetters("OTStore", ["ot", "statusRequest", "files", "uploadingFile"]),

    attachmentTypes() {
      if (this.newAttachmentType === ARCHIVO_ADJUNTO_TIPO.BOLETA) {
        return [{ value: ARCHIVO_ADJUNTO_TIPO.BOLETA, text: "Boleta" }]
      }
      const types = [
        { value: ARCHIVO_ADJUNTO_TIPO.INGRESO, text: "Ingreso" },
        { value: ARCHIVO_ADJUNTO_TIPO.EVIDENCIA, text: "Evidencia" },
        { value: ARCHIVO_ADJUNTO_TIPO.OTROS, text: "Otros" },
      ]
      if (!this.allFiles.some((f) => !f.isNew && f.type.toLowerCase() === ARCHIVO_ADJUNTO_TIPO.BOLETA.toLowerCase())) {
        types.push({ value: ARCHIVO_ADJUNTO_TIPO.BOLETA, text: "Boleta" })
      }
      return types
    },

    canUploadMore() {
      if (this.newAttachmentType === ARCHIVO_ADJUNTO_TIPO.BOLETA && this.allFiles?.filter(f => f.isNew)?.length > 0) return false
      return true
    },
  },
  methods: {
    ...mapActions("OTStore", [
      "uploadFile",
      "getFiles",
      "deleteFile",
      "downloadFile",
      "downloadPdfCambio",
      "getFileUrl",
    ]),
    ...mapMutations("OTStore", ["SET_FILE", "SET_FILES"]),
    addOldFiles() {
      const oldFiles =
        this.files.map((f, index) => ({
          number: index + 1,
          name: f.name ? f.name.split("/").pop() : "-",
          type: f.type_label,
          file: f,
          isNew: false,
        })) || [];
      this.allFiles = oldFiles;
    },
    handleFileUpload() {
      // this.file = this.$refs.file.files[0] // eslint-disable-line
      this.filesToUpload = Array.from(this.$refs.file.files);
      // this.SET_FILE(this.file)
    },
    addFile() {
      const ALLOWED_TYPES = ["image/jpeg", "image/png", "application/pdf"];
      if (this.filesToUpload.some((f) => !ALLOWED_TYPES.includes(f.type))) {
        return this.$bvToast.toast("Solo se permiten archivos .jpg, .png, .pdf ");
      }

      // this.file.isNew = true
      this.filesToUpload.map((f) => ({ ...f, isNew: true }));
      if (this.limitToOne) {
        console.log(this.filesToUpload);
        this.newFiles = [this.filesToUpload[0]];
        this.allFiles.push({
          number: this.allFiles.length + 1,
          name: this.filesToUpload[0].name,
          type: this.newAttachmentType,
          file: this.filesToUpload[0],
          isNew: true,
        });
      } else {
        this.newFiles.push(...this.filesToUpload);
        this.allFiles.push(...this.filesToUpload.map((f, i) => ({
          number: this.allFiles.length + i + 1,
          name: f.name,
          type: this.newAttachmentType,
          file: f,
          isNew: true,
        })));
      }
      this.filesToUpload = [];
    },
    async uploadNewFiles() {
      this.allFiles.forEach(async (f) => {
        if (f.isNew) {
          await this.submitFile(f);
        }
      });
      this.newFiles = [];
      this.$emit("done");
    },
    async submitFile(data) {
      this.loading = true
      const formData = new FormData();
      if (this.fileNamePattern) {
        const ext = data.file.name.split(".")[1];
        formData.append(
          "archivo",
          data.file,
          `ot-${this.ot.ot}-${this.fileNamePattern}-${this.fileCount}.${ext}`
        );
        this.fileCount += 1;
      } else {
        formData.append("archivo", data.file);
      }
      if (this.ot) formData.append("orden_trabajo", this.ot.ot);
      if (this.otId) formData.append("orden_trabajo", this.otId);
      formData.append("tipo", data.type);
      const resp = await this.uploadFile(formData);
      if (resp.status === 201) {
        this.$bvToast.toast("Archivo subido correctamente.", {
          variant: "success",
        });
      } else if (resp.data.archivo && resp.data.archivo.length > 0) {
        this.$bvToast.toast(`Error subir archivo: ${resp.data.archivo[0]}`, {
          variant: "danger",
        });
      }
      this.loading = false
    },
    async removeItem(item) {
      if (item.file?.isNew) {
        const index = this.newFiles.indexOf(item.file);
        this.newFiles.splice(index, 1);
        return;
      }
      await this.deleteFile(item.file?.file_id || item.file_id);
      await this.getFiles(this.ot.ot);
      await this.updateThumbnails()
    },
    async downloadItem(item) {
      this.downloading.id = item.file_id;
      this.downloading.status = true;
      const payload = {
        fileName: item.file ? item.name : item.name.split('/').pop(),
        fileType: item.file?.file_type || item.file_type,
        fileId: item.file?.file_id || item.file_id,
      };
      try {
        const fileType = item.file?.type || item.type
        if (fileType === "PDF Cambio") {
          await this.downloadPdfCambio(this.ot);
        } else {
          await this.downloadFile(payload);
        }
      } catch (error) {
        this.$bvToast.toast(error, {
          variant: "danger",
        });
      } finally {
        this.downloading.id = null;
        this.downloading.status = false;
      }
    },
    checkSelected(item) {
      if (this.allFiles.filter((f) => f.type.toLowerCase() === ARCHIVO_ADJUNTO_TIPO.BOLETA.toLowerCase()).length > 1) {
        item.type = ARCHIVO_ADJUNTO_TIPO.OTROS
      }
    },
    getThumbIcon(file) {
      const FILETYPE_ICONS = {
        'pdf': { icon: this.iconFilePdfBox, class: 'thumb-icon-pdf'},
      }
      if (!this.notAnImage(file.file_type)) return { icon: this.iconImage, class: 'thumb-icon-image' }
      return FILETYPE_ICONS[file.file_type]
    },
    getThumbLabel(file) {
      const FILE_LABELS = {
        'BOLETA': 'Boleta',
        'EVIDENCIA': 'Evidencia',
        'INGRESO': 'Ingreso',
        'OTROS': 'Otros'
      }
      return FILE_LABELS[file.type.toUpperCase()] || 'Otros'
    },
    notAnImage(fileType) {
      return !['png', 'jpg', 'jpeg', 'gif'].includes(fileType)
    },
    async updateThumbnails() {
      if (this.galleryMode) {
        this.files.forEach(async (item, idx) => {
          const payload = {
            fileName: item.name,
            fileType: item.file_type,
            fileId: item.file_id,
          }
          this.$set(this.fileBlobUrls, idx, await this.getFileUrl(payload))
        })
      }
    },
    async downloadAllImages() {
      this.downloadingAll = true
      const zip = new JSZip()
      const folder = zip.folder(`Adjuntos_OT_${this.ot.ot}`)
      await Promise.all(
        this.fileBlobUrls.map(async (url, index) => {
          if (!url) return
          const blob = await fetch(url).then((r) => r.blob())
          const fileName = this.files[index].name.split('/').pop()
          folder.file(fileName, blob)
        })
      )
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        fileDownload(content, `Adjuntos_OT_${this.ot.ot}.zip`)
      })
      this.downloadingAll = false
    },
  },
  async mounted() {
    if (this.ot?.equipo) {
      await this.getFiles(this.ot.ot)
      await this.updateThumbnails()
    }
  },
  created() {
    // this.file = this.uploadingFile
    if (!this.ot?.equipo) {
      this.SET_FILES([]);
    }
    this.addOldFiles();
  },
  watch: {
    ot() {
      if (this.ot) {
        this.getFiles(this.ot.ot);
      }
    },
    files() {
      this.addOldFiles()
      this.updateThumbnails()
    },
    // uploadingFile() {
    //   this.file = this.uploadingFile
    // },
  },
};
</script>

<style lang="scss" scoped>
.cursive {
  font-style: italic;
}
.size-small {
  width: 200px;
}
.big-size {
  font-size: 10rem;
}
#file {
  font-weight: 600;
  font-size: 15px;
}
.file-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 2px dashed gray;
  border-radius: 1rem;
  width: 100%;
  position: relative;
  height: 130px;
  cursor: pointer;

  &:hover {
    border-color: var(--primary);
    .fake-button {
      background-color: #1148b5;
    }
  }

  &:active {
    transform: scale(1.01);
  }

  .fake-button {
    background-color: var(--button);
    border-radius: 5px;
    color: white;
    padding: 0.5rem 0.8rem;
    transition: all 0.2s ease-out;
  }
}
.fileListWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  span {
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 5px;
  }
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(131px, 1fr));
  gap: 20px;
}

.thumbnail-item {
  background-color: #F7FAFC;
  padding: 10px;
}

.thumbnail-item-inner {
  width: 145px;
  height: 131px;
  border-radius: 10px;
  background-color: #CBCBCB;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  ::v-deep .img-thumbnail {
    max-height: 131px;
    object-fit: cover;
    padding: 0;
    border: 0;
  }
}

.thumb-file-name {
  font-weight: bold;
  font-size: 15px;
  color: #525252;
  text-overflow: ellipsis;
  width: 80px;
  overflow: hidden;
  text-wrap: nowrap;
}

.thumb-type-label {
  font-size: 15px;
  font-style: italic;
  color: #525252;
}

.thumb-icon-pdf {
  color: #FF585F;
}

.thumb-icon-image {
  color: #5B73E8;
}

.thumb-dropdown {
  ::v-deep .dropdown-toggle {
    padding: 0;
    // background-color: transparent;
    border: 0;
    // color: #525252;
  }
}

.main-wrapper {
  position: relative;
}

.download-all-button-wrapper {
  position: absolute;
  top: -56px;
  right: 0;
}
</style>
