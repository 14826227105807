<template lang="pug">
  #DatosCliente
    b-card.form-card
      b-row.pb-2
        span#icon 1
        h5.title.mt-2 Datos del Cliente
      //- hr
      b-row.pt-4
        b-col(cols='12' md='2')
          b-form-group(class="rut" label-for="input-rut" :label-class="activeField === 'rut' ? 'active-label' : ''")
            template( #label ) RUT:
            b-form-input(
              placeholder="Ej: 76872144-0"
              id="input-rut"
              v-model="rutFormatted"
              :state="$v.datosProvisorios.rut.$dirty? !$v.datosProvisorios.rut.$invalid : null"
              @input="$v.datosProvisorios.rut.$touch()"
              @focus="activeField = 'rut'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.rut.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.rut.required")
                | Campo requerido
      b-row
        b-col(cols='12' md='6')
          b-form-group(class="nombre" label-for="input-nombre" :label-class="activeField === 'nombre' ? 'active-label' : ''")
            template( #label ) Nombre:
            b-form-input(
              id="input-name"
              v-model="datosProvisorios.nombre"
              placeholder="Nombre"
              :state="$v.datosProvisorios.nombre.$dirty? !$v.datosProvisorios.nombre.$invalid : null"
              @input="$v.datosProvisorios.nombre.$touch()"
              @focus="activeField = 'nombre'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.apellido.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.nombre.required")
                | Campo requerido
        b-col(cols='12' md='6')
          b-form-group(class="apellido" label-for="input-apellido" :label-class="activeField === 'apellido' ? 'active-label' : ''")
            template( #label ) Apellidos:
            b-form-input(
              id="input-apellido"
              v-model="datosProvisorios.apellido"
              placeholder="Apellidos"
              :state="$v.datosProvisorios.apellido.$dirty? !$v.datosProvisorios.apellido.$invalid : null"
              @input="$v.datosProvisorios.apellido.$touch()"
              @focus="activeField = 'apellido'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.apellido.$dirty")
                b-form-invalid-feedback(:state="$v.datosProvisorios.apellido.required")
                    | Campo requerido
      b-row
        b-col(cols='12' md='4')
          b-form-group(class="region" label-for="input-region")
            template( #label ) Región:
            b-form-select(
              id="input-city"
              v-model="datosProvisorios.region"
              :options="regionOptions"
              :state="$v.datosProvisorios.region.$dirty? !$v.datosProvisorios.region.$invalid : null"
            )
            .errors(v-if="$v.datosProvisorios.region.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.region.required")
                | Campo requerido
        b-col(cols='12' md='4')
          b-form-group(class="comuna" label-for="input-comuna")
            template( #label ) Comuna:
            b-form-select(
              id="input-comuna"
              v-model="datosProvisorios.comuna"
              :options="comunaOptions"
              :state="$v.datosProvisorios.region.$dirty? !$v.datosProvisorios.comuna.$invalid : null"
            )
            .errors(v-if="$v.datosProvisorios.comuna.$dirty")
              b-form-invalid-feedback(:state="$v.datosProvisorios.comuna.required")
                | Campo requerido
        b-col(cols='12' md='4')
          b-form-group(class="direccion" label-for="input-direccion" :label-class="activeField === 'direccion' ? 'active-label' : ''")
            template( #label ) Dirección:
            b-form-input(
              id="input-address"
              v-model="datosProvisorios.direccion"
              :state="$v.datosProvisorios.direccion.$dirty? !$v.datosProvisorios.direccion.$invalid : null"
              @input="$v.datosProvisorios.direccion.$touch()"
              @focus="activeField = 'direccion'"
              @blur="activeField = ''"
            )
            .errors(v-if="$v.datosProvisorios.direccion.$dirty")
                b-form-invalid-feedback(:state="$v.datosProvisorios.direccion.required")
                  | Campo requerido
      b-row
          b-col(cols='12' md='6')
            b-form-group(class="telefono" label-for="input-telefono" :label-class="activeField === 'telefono' ? 'active-label' : ''")
              template( #label ) Teléfono:
              b-form-input(
                id="input-telefono"
                v-model="datosProvisorios.telefono"
                placeholder="Ingresa un número de contacto"
                :state="$v.datosProvisorios.telefono.$dirty? !$v.datosProvisorios.telefono.$invalid : null"
                @input="$v.datosProvisorios.telefono.$touch()"
                maxLength="9"
                @focus="activeField = 'telefono'"
                @blur="activeField = ''"
              )
              .errors(v-if="$v.datosProvisorios.telefono.$dirty")
                  b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.required")
                      | Campo requerido
                  b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.numeric")
                      | Solo puedes ingresar numeros
                  b-form-invalid-feedback(:state="$v.datosProvisorios.telefono.minLength")
                      | Tienes que ingresar 8 digitos
          b-col(cols='12' md='6')
            b-form-group(class="email" label-for="input-email" :label-class="activeField === 'email' ? 'active-label' : ''")
              template( #label ) Email:
              b-form-input(
                id="input-email"
                v-model="datosProvisorios.email"
                placeholder="Ingresa tu correo electrónico"
                :state="$v.datosProvisorios.email.$dirty? !$v.datosProvisorios.email.$invalid : null"
                @input="$v.datosProvisorios.email.$touch()"
                @focus="activeField = 'email'"
                @blur="activeField = ''"
              )
              .errors(v-if="$v.datosProvisorios.email.$dirty")
                  b-form-invalid-feedback(:state="$v.datosProvisorios.email.required")
                    | Campo requerido
                  b-form-invalid-feedback(:state="$v.datosProvisorios.email.email")
                    | No es una direccion de correo valida
      .d-flex.justify-content-end
        .botones(class="btn")
          b-button.primary-btn(@click="onSubmit()" small variant="samtek-yellow")
            | Siguente Paso
</template>

<script>
import { required, email, numeric, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';
import validateRut from '../../../../utils/validateRut'
import validateEmail from '../../../../utils/validateEmail'
import * as cts from '../../constants'



export default {
  props: ['setDatosProvisorios', 'datosReturn'],
  data() {
    return {
      query: '',
      required: false,
      emailInvalid: true,
      creating: false,
      dirty: false,
      activeField: false,
      cts,
      datosProvisorios: {
        nombre: this.datosReturn.nombre ? this.datosReturn.nombre : null,
        apellido: this.datosReturn.apellido ? this.datosReturn.apellido : null,
        rut: this.datosReturn.rut ? this.datosReturn.rut : null,
        email: this.datosReturn.email ? this.datosReturn.email : null,
        comuna: this.datosReturn.comuna ? this.datosReturn.comuna : null,
        telefono: this.datosReturn.telefono ? this.datosReturn.telefono : null,
        direccion: this.datosReturn.direccion
          ? this.datosReturn.direccion
          : null,
        region: this.datosReturn.region ? this.datosReturn.region : null,
      },
    }
  },
  validations: {
    datosProvisorios: {
      rut: { required },
      nombre: { required },
      apellido: { required },
      email: { required, email },
      telefono: {
        required,
        numeric,
        minLength: minLength(8),
      },
      direccion: { required },
      comuna: { required },
      region: { required },
    },
  },
  methods: {
    setNewClient() {
      try {
        const splitted = this.query.split('-')
        const loweredDigit = splitted[1].toString().toLowerCase()
        const rut = `${splitted[0]}-${loweredDigit}`
        this.datosProvisorios.rut = rut
      } catch {
        this.$bvToast.toast('Ingrese un rut válido. Ej: 12345678-9', {
          variant: 'danger',
        })
      }
    },
    async onSubmit() {
      if (
        this.$v.datosProvisorios.nombre.$invalid ||
        this.$v.datosProvisorios.apellido.$invalid ||
        this.$v.datosProvisorios.region.$invalid ||
        this.$v.datosProvisorios.comuna.$invalid ||
        this.$v.datosProvisorios.direccion.$invalid ||
        this.$v.datosProvisorios.telefono.$invalid ||
        this.$v.datosProvisorios.email.$invalid ||
        this.$v.datosProvisorios.rut.$invalid
      ) {
        this.$bvToast.toast('Rellene todos los campos requeridos', {
          variant: 'danger',
        })
        this.$v.$touch()
      } else {
        this.setDatosProvisorios(this.datosProvisorios)
      }
    },
    tagValidator(tag) {
      // Individual tag validator function
      const validate = validateEmail(tag)
      if (validate) {
        this.emailInvalid = true
        return validate
      }
      this.emailInvalid = false
      return validate
    },
  },
  computed: {
    rutIsValid() {
      return validateRut(this.datosProvisorios.rut || '')
    },
    rutFormatted: {
      get() {
        return this.datosProvisorios.rut?.replace(/\./g, '')
      },
      set(v) {
        // this.rut = v?.replace(/\./g, '')
        const rutLimpio = v.replace(/[^0-9kK]/g, '');

        // asilar el cuerpo del dígito verificador
        const cuerpo = rutLimpio.slice(0, -1);
        const dv = rutLimpio.slice(-1).toUpperCase();

        if (rutLimpio.length < 2) return rutLimpio;

        // colocar los separadores de miles al cuerpo
        let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');

        cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, ''); // eslint-disable-line

        this.datosProvisorios.rut = `${cuerpoFormatoMiles.replace(/[^0-9kK]/g, '')}-${dv}`
      },
    },
    regionOptions() {
      return this.regiones.map((region) => ({
        value: region.region,
        text: region.region,
      }))
    },
    comunaOptions() {
      if (this.datosProvisorios.region) {
        const region = this.regiones.find(
          (c) => c.region === this.datosProvisorios.region
        )
        if (region && region.comunas) {
          return region.comunas.map((c) => ({
            value: c,
            text: c,
          }))
        }
      }
      return [
        {
          value: null,
          text: 'Selecione',
        },
      ]
    },
    ...mapGetters('ClientStore', ['regiones']),
  },
  watch: {
    datosReturn: {
      handler() {
        this.datosProvisorios = {
          nombre: this.datosReturn.nombre ? this.datosReturn.nombre : null,
          apellido: this.datosReturn.apellido ? this.datosReturn.apellido : null,
          rut: this.datosReturn.rut ? this.datosReturn.rut : null,
          email: this.datosReturn.email ? this.datosReturn.email : null,
          comuna: this.datosReturn.comuna ? this.datosReturn.comuna : null,
          telefono: this.datosReturn.telefono ? this.datosReturn.telefono : null,
          direccion: this.datosReturn.direccion
            ? this.datosReturn.direccion
            : null,
          region: this.datosReturn.region ? this.datosReturn.region : null,
        }
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;

  .title {
    letter-spacing: 0px;
    color: $samtek-black;
    opacity: 1;
    font-weight: 600;
    /* font-size: 20px; */
  }
}
.form-group::v-deep label {
  color: $samtek-grey;

  &.active-label {
    color: $samtek-black;
  }
}
.em {
  color: $samtek-black;
  font-weight: 700;
}
#icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: $samtek-black;
  background-color: $samtek-yellow;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  font-size: 18px;
}
.primary-btn {
  font-weight: 600;
}
</style>
