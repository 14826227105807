<template lang="pug">
#OVActions
  .actions-buttons
    b-button(
      variant="primary"
      @click="reloadActions"
    )
      | Acciones
      b-spinner(v-if="loading" small)
  b-modal.modal(v-model="showActions" hide-footer title="Acciones")
    span(style="width: 100%; text-align: center; color: #f4c235;" v-if="loading")
      b-spinner
    b-col(
      cols="12"
      v-for="action in actions"
      v-if="ovActions.includes(action.actionCondition) && loading === false"
      :key="action.actionCondition"
    )
      component(:is="action.component" style="margin-bottom: 10px" :ovId="ovId" @reload="reloadActions")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import GenerarCotizacion from './Actions/GenerarCotizacion.vue'
import AceptarCotizacion from './Actions/AceptarCotizacion.vue'
import RechazarCotizacion from './Actions/RechazarCotizacion.vue';
import PedirRepuestosBodega from './Actions/PedirRepuestosBodega.vue';
import PrepararNuevaCotizacion from './Actions/PrepararNuevaCotizacion.vue';
import CerrarOVRechazado from './Actions/CerrarOVRechazado.vue';
import RepuestosRecibidosBodega from './Actions/RepuestosRecibidosBodega.vue';
import RepuestosRecibidosEjecutivo from './Actions/RepuestosRecibidosEjecutivo.vue';
import IngresarPago from './Actions/IngresarPago.vue';

export default {
  data() {
    return {
      loading: false,
      showActions: false,
      actions: [
        {
          component: GenerarCotizacion,
          actionCondition: 'GenerarCotizacion',
        },
        {
          component: AceptarCotizacion,
          actionCondition: 'AceptarCotizacion',
        },
        {
          component: RechazarCotizacion,
          actionCondition: 'RechazarCotizacion',
        },
        {
          component: PedirRepuestosBodega,
          actionCondition: 'PedirRepuestosBodega',
        },
        {
          component: PrepararNuevaCotizacion,
          actionCondition: 'PrepararNuevaCotizacion',
        },
        {
          component: CerrarOVRechazado,
          actionCondition: 'CerrarOVRechazado',
        },
        {
          component: RepuestosRecibidosBodega,
          actionCondition: 'RepuestosRecibidosBodega',
        },
        {
          component: RepuestosRecibidosEjecutivo,
          actionCondition: 'RepuestosRecibidosEjecutivo',
        },
        {
          component: IngresarPago,
          actionCondition: 'IngresarPago',
        },
      ],
    }
  },
  props: {
    ovId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions('OVStore', ['listOvActions']),
    async reloadActions(hasResponse=false, messageTitle=null, message=null, messageVariant=null) {
      this.loading = true
      await this.listOvActions(this.ovId)
      this.showActions = true
      this.loading = false
      // Funcionalidad para la respuesta de las acciones
      if (hasResponse) {
        this.$bvToast.toast(message, {
          title: messageTitle,
          variant: messageVariant,
          solid: true,
        })
      }
    }
  },
  computed: {
    ...mapGetters('OVStore', ['ovActions']),
  },
}
</script>
<style lang="scss">
@import url('./Styles/Index.scss');
</style>
