<template lang="pug">
#AccordionParts
  .form-card
    .accordion(class="accordion" role="tablist" @click="openAndClose")
      h2.title-data(
        block=''
        v-b-toggle="`accordion-${numberParts}`"
      )
        .flex-row.gap-2
          span Repuesto {{numberParts + 1}}
          b-icon.icon(:icon="isOpen ? 'chevron-up' : 'chevron-down'")
    .content.pt-2
      slot
</template>

<script>
export default {
  name: 'AccordionParts',
  props: ['numberParts'],
  data() {
    return {
      isOpen: true,
    }
  },
  methods: {
    openAndClose() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 15px;
  margin-bottom: 18px;

  .title-data {
    letter-spacing: 0px;
    color: $samtek-black;
    opacity: 1;
    font-weight: 700;
    font-size: 18px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: $samtek-black;
  }
}
</style>
