<template lang="pug">
.loader(v-if="loading")
  b-spinner(label='Cargando...' variant="danger")
div(v-else)
  .d-flex.justify-content-between
    h1(id="title-ot").title Orden de Trabajo {{ $route.params.order_id }}
    b-breadcrumb( :items="breadcrumbs" )
  .d-flex.cards
    b-card( body-class="main-card" ).first

      .main-content
        .info-bar
          .sub
            .d-flex.gap10
              div.d-sm-block.d-md-block.d-lg-none
                .d-flex.gap-3.flex-column.align-items-start.mb-2
                    div.time-elapsed.info-box
                      span Tiempo<br>Estado Actual:
                      b-icon(:icon='currentStatusTime.icon' :variant='currentStatusTime.variant' font-scale="1.5")
                      span {{ currentStatusTime.time }}
                      StateTimeDetails.ml-n1(v-if="showStateTimeDetails" :detail="ot.detalle_tiempo_compuesto")
                    div.time-elapsed.info-box
                      span Tiempo<br>Total:
                      b-icon(:icon='totalTime.icon' :variant='totalTime.variant' font-scale="1.5")
                      span {{ totalTime.time }}
                    div.next-step.info-box
                      span Próximo Paso:
                      span {{ textoSiguientePaso }}
            div.d-none.d-lg-flex
              span.status( :style="getColor()" ) {{ textoEstadoOT }}
              span.location.ml-2.mr-2 {{ textoUbicaciónOT }}
              .d-flex.gap-3.flex-column.flex-sm-row
                div.time-elapsed.info-box
                  span Tiempo<br>Estado Actual:
                  b-icon( :icon='currentStatusTime.icon' :variant='currentStatusTime.variant' font-scale="1.5" )
                  span {{ currentStatusTime.time }}
                  StateTimeDetails.ml-n1(v-if="showStateTimeDetails" :detail="ot.detalle_tiempo_compuesto")
                div.time-elapsed.info-box
                  span Tiempo<br>Total:
                  b-icon( :icon='totalTime.icon' :variant='totalTime.variant' font-scale="1.5" )
                  span {{ totalTime.time }}
          .sub.d-lg-flex
            div.next-step.info-box
              span Próximo Paso:
              span {{ textoSiguientePaso }}
            .alertas.d-flex.gap10.flex-column.flex-sm-row.mb-2
              //- alerta excepción
              .alerta( v-if="exception" :class="[ exception.class ]" class="alerta-cursor" @click="details3x3(exception)" )
                component(font-scale="1.5" :variant="exception.variant" :is="exception.component" )
                span {{ exception.tipo }}
              //- alerta presupuesto
              .alerta( v-if="ultimaCotizacion" :class="[ ultimaCotizacion.class ]" )
                component( font-scale="1.5" :variant="ultimaCotizacion.variant ? ultimaCotizacion.variant : 'success'" :is="ultimaCotizacion.component" )
                span Presupuesto {{ ultimaCotizacion.estado }}
        DetailsApproval(:exception="exception" :ot="ot")
        b-tabs( :value="getCurrentTabFromRoute()" fill nav-wrapper-class="tab-wrapper" )
          b-tab( title="Resumen de Ingreso" )
            DiagnoseForm( v-if="ot" )
          b-tab( title="Diagnóstico y Reparación" lazy )
            Diagnostic(@changed="() => handleChanged()")
            SolicitudPartes
          b-tab( title="Informe PDF" lazy )
            InformePDF
          b-tab( title="Presupuestos" v-if="ot.tipo_servicio.nombre !== 'Garantía'" )
            DetailInternalQuotations( :ot="ot" )
          b-tab( title="Históricos" lazy )
            StatesHistory(:otId="$route.params.order_id")
            Movements
            Exceptions(:otId="$route.params.order_id")
            InventoryMovements(:otId="$route.params.order_id")
            ActionLogsFromOT(:otId="$route.params.order_id" :dateStart="ot.fecha_ingreso" :dateEnd="dateNow")

      .action-bar
        .actions-scroll-section
          b-row.d-sm-block.d-md-none

            b-col(cols="5" sm="5")
                b-dropdown(
                id="dropdown-actions"
                text="Acciones"
                variant="primary"

                class="mt-2"
              )
                  template(v-slot:button-content)
                    span Acciones

                  b-dropdown-item(@click="printSummary()")
                    p(class="text-print") Imprimir
                  b-dropdown-item(@click="generateCode()")
                    PrintBarCode
                  OTActionsIndex(
                    :ot="actionsOT"
                    :loading="loading"
                    @changed="() => handleChanged()"
                  )
            b-col(cols="7" sm="7")
              .info-bar
                .sub
                  .d-flex.gap10
                    span.status( :style="getColor()" ) {{ textoEstadoOT }}
                    span.location {{ textoUbicaciónOT }}
          OTActionsIndex(
            :ot="actionsOT"
            :loading="loading"
            @changed="() => handleChanged()"
          ).d-none.d-md-block
        .print-buttons.mt-xl-auto.ml-xl-0.ml-md-auto.d-none.d-md-block
          PrintBarCode.mb-2
          b-button.imprimir(disabled, v-if="loadingPrint")
            b-spinner(small='' type='grow')
            | Cargando...
          b-button.imprimir(@click="printSummary()" v-else)
            b-icon-printer.icon-printer
            | Imprimir
    b-card.ml-3.mb-5.warehouse-card(v-if="showEntryForm")
      EntryForm
    b-card.ml-3.mb-5.warehouse-card(v-if="showDepartureForm")
      DepartureForm
</template>

<script>
import { io } from 'socket.io-client'
import printJS from 'print-js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import settings from '@/../settings'
import PrintBarCode from '@/components/PrintBarCode.vue'
import InformePDF from '@/components/OT/InformePDF.vue'
import Exceptions from '@/components/OT/ExceptionDetail.vue'
import InventoryMovements from '@/components/OT/InventoryMovementsHistory.vue'
import Diagnostic from '@/components/Diagnostic/Diagnostic.vue'
import SolicitudPartes from '@/components/AskParts/SolicitudPartes.vue'
import Movements from '@/components/Warehouse/Movements.vue'
import EntryForm from '@/components/Warehouse/EntryForm.vue'
import DepartureForm from '@/components/Warehouse/DepartureForm.vue'
import formatElapsedTime, { formatElapsedTimeInHours } from '@/utils/formatElapsedTime'
import DiagnoseForm from './DiagnoseForm.vue'
import StatesHistory from './StatesHistory.vue'
import ActionLogsFromOT from './ActionLogsFromOT.vue'
import OTActionsIndex from '../OTActions/OTActionsIndex.vue'
import DetailInternalQuotations from '../../Quotations/DetailInternalQuotations.vue'
import StateTimeDetails from './StateTimeDetails.vue'
import DetailsApproval from './ModalDetails3x3.vue'

const [TAB_RESUMEN, TAB_HISTÓRICO] = [0, 4]
const alertProps = {
  Solicitado: {
    component: 'b-icon-exclamation-circle-fill',
    variant: 'warning',
    class: 'solicitado',
  },
  Aprobado: {
    component: 'b-icon-check-circle-fill',
    variant: 'success',
    class: 'aprobado',
  },
  Rechazado: {
    component: 'b-icon-x-circle-fill',
    variant: 'danger',
    class: 'rechazado',
  },
}

alertProps.SOLICITADO = alertProps.Solicitado
alertProps.APROBADO_INTERNO = alertProps.Aprobado
alertProps.RECHAZADO_INTERNO = alertProps.Rechazado
alertProps.APROBADO_CLIENTE = alertProps.Aprobado
alertProps.RECHAZADO_CLIENTE = alertProps.Rechazado

const PROPS_BY_COLOR = {
  green: { icon: 'check-circle', variant: 'estado-green' },
  yellow: { icon: 'exclamation-circle-fill', variant: 'estado-yellow' },
  red: { icon: 'exclamation-triangle-fill', variant: 'estado-red' },
}

export default {
  name: 'ViewOTIndex',
  components: {
    DiagnoseForm,
    InformePDF,
    Diagnostic,
    SolicitudPartes,
    StatesHistory,
    Movements,
    EntryForm,
    DepartureForm,
    PrintBarCode,
    OTActionsIndex,
    Exceptions,
    DetailInternalQuotations,
    InventoryMovements,
    ActionLogsFromOT,
    StateTimeDetails,
    DetailsApproval,
  },
  data() {
    return {
      exception: null,
      loading: true,
      loadingPrint: false,
      timeElapsed: 0,
      momentNow: null,
      // interval: null,
      updateTimeInterval: null,
      show: false,
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { path: '/' },
        },
        {
          text: 'Ordenes de Trabajo',
          to: { path: '/listar-ordenes' },
        },
        {
          text: `OT ${this.$route.params.order_id}`,
          active: true,
        },
      ],
    }
  },
  async created() {
    this.loading = true
    await this.getOT(this.$route.params.order_id)
    // this.interval = setInterval(() => {
    //   this.timeElapsed += 1
    // }, 1000)
    this.loading = false
    const { user } = JSON.parse(localStorage.getItem('user'))
    const roles = user.groups.map((t) => t.id)
    if (roles.indexOf(2) > -1 && !this.ot.rma) this.isSupervisor = true
    if (roles.indexOf(3) > -1) this.isSupervisor = true
    await this.getOtExceptionsHistory(this.ot.ot).then((res) => {
      const firstException = res.data[0]
      this.exception = firstException
        ? this.mapAlertObject(firstException)
        : null
    })

    this.initWebsockets()
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibility, false)
    this.updateTimeInterval = setInterval(() => {
      this.updateOtTimes()
    }, 60000)
  },
  beforeDestroy() {
    // clearInterval(this.interval)
    clearInterval(this.updateTimeInterval)
  },
  destroyed() {
    if (!this.socket) return
    this.socket.disconnect()
  },
  methods: {
    ...mapActions('OTStore', ['getOT', 'getPdfIngreso']),
    ...mapMutations('OTStore', ['SET_PRINT_NO_DISPLAY', 'SET_OT']),
    ...mapMutations('Exceptions', [
      'SET_SHOW_MODAL',
    ]),
    ...mapActions('OT', ['getOtExceptionsHistory']),
    async handleChanged() {
      this.loading = true
      await this.getOT(this.$route.params.order_id)
      this.loading = false
    },
    details3x3 (exception) {

    if(exception.tipo === "Aprobación de 3x3"){
      this.SET_SHOW_MODAL(true);
    }
  },
    async printSummary() {
      this.loadingPrint = true
      const resp = await this.getPdfIngreso(this.ot.ot)
      if (resp.status === 200) {
        // imprimir pdf de respuesta
        const file = new Blob([resp.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        printJS(fileURL)
      }
      this.loadingPrint = false
    },
    getColor() {
      const estado = this.ot?.estado?.estado
      if (estado) {
        return `background-color: ${estado.color_background}; color: ${estado.color_foreground};`
      }
    },
    getCurrentTabFromRoute() {
      return this.$route.query.from === 'excepciones'
        ? TAB_HISTÓRICO
        : TAB_RESUMEN
    },
    handleVisibility() {
      if (document.hidden) {
        clearInterval(this.updateTimeInterval)
      } else {
        this.updateTimeInterval = setInterval(() => {
          this.updateOtTimes()
        }, 60000)
        this.updateOtTimes()
      }
    },
    async updateOtTimes() {
      if (this.$route.meta.allowRefresh) {
        console.log('Updating OT (60 seconds interval)')
        await this.getOT(this.$route.params.order_id)
      }
    },
    // Recibe un objeto excepción o presupuesto
    mapAlertObject(o) {
      return { ...o, ...alertProps[o.estado_code || o.estado] }
    },

    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)

      this.socket.on('orden_trabajo:updated', async ({ id }) => {
        if (id === this.ot.ot) {
          await this.getOT(this.$route.params.order_id)
        }
      })
    },
  },
  computed: {
    ...mapGetters('OTStore', ['ot']),
    ...mapGetters('Diagnosticos', ['showDiagnosticForm']),
    ...mapGetters('Warehouse', ['showEntryForm']),
    ...mapGetters('Warehouse', ['showDepartureForm']),
    ...mapGetters('Exceptions', [
      'showModal'
    ]),
    actionsOT() {
      const newOt = this.ot // eslint-disable-line
      newOt.recepcionista = this.ot.recepcionista
        ? this.ot.recepcionista.id
        : null
      return newOt
    },
    textoSiguientePaso() {
      return this.ot?.estado?.estado?.siguiente_paso || '---'
    },
    textoEstadoOT() {
      return this.ot?.estado?.estado?.nombre || '---'
    },
    textoUbicaciónOT() {
      return this.ot?.ubicacion || '---'
    },
    currentStatusTime() {
      const time = this.ot?.tiempo_estados?.hora_compuesta_actual || this.ot?.tiempo_estados?.hora_estado_actual
      if (!time) return { time: '00:00' }
      const data = PROPS_BY_COLOR[this.ot?.detalle_alerta?.state?.color] || {}
      return { ...data, time: formatElapsedTimeInHours(time, false) || '' }
    },
    totalTime() {
      const time = this.ot?.tiempo_estados?.hora_total
      if (!time) return { time: '0d 00:00:00' }
      const data = PROPS_BY_COLOR[this.ot?.detalle_alerta?.total?.color] || {}
      // const liveTime = time + this.timeElapsed
      return { ...data, time: formatElapsedTime(time, false) || '---' }
    },
    ultimaCotizacion() {
      const quotations = this.ot?.solicitud_cotizacion_interna
      if (!quotations || quotations.length === 0) return null

      const lastQuotation = quotations.at(-1)
      return this.mapAlertObject(lastQuotation)
    },
    dateNow() {
      return new Date().toISOString()
    },
    showStateTimeDetails() {
      return !!this.ot?.tiempo_estados?.hora_compuesta_actual
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.title {
  font-size: 21px;
  font-weight: bold;
  color: var(--info);
}

.cards::v-deep {
  .card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    border: none;
  }
  .warehouse-card {
    width: 45%;
    min-height: 80vh;
  }
}

.first {
  width: 100%;
  min-height: 400px;
}

.main-card {
  display: flex;
  height: calc(100vh - 176px);
  flex-direction: column-reverse;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  padding-right: 10px;

  padding-top: 25px;
  margin-top: 20px;
  border-top: 5px solid #e4e4e4;
  @include media-breakpoint-up(xl) {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
    padding-right: 20px;
    margin-right: 20px;
    // border-right: 5px solid #E4E4E4;
  }

  &::v-deep .tab-pane {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}

.action-bar {
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }
  .buttons-ot {
    border-radius: 5px;
    width: 100%;
    background: rgb(232, 235, 242);
    padding: 10px;
  }
}

.actions-scroll-section {
  @include media-breakpoint-between(md, lg) {
    max-width: calc(100% - 370px);
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
.text-print {
  color: #5B73E8;
  padding-left: 0 !important;
  margin-left: -5px;
  margin-bottom: 0px;
}
.print-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  flex-direction: row;
  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    height: 40px;
    margin-top: 1rem;
  }

  &::v-deep button {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    width: 170px;
    height: 50px;

    @include media-breakpoint-down(xs) {
      width: 120px;
    }
  }
  @include media-breakpoint-up(lg) {
    &::v-deep button {
      height: 45px;
    }
  }
}

::v-deep .imprimir {
  border: none;
  background: #5b73e8 0% 0% no-repeat padding-box;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

::v-deep .nav-tabs {
  border-bottom: none !important;
  .nav-link {
    color: #5b73e8 !important;
    font-weight: normal !important;
    border-bottom: 2px solid #dee2e6 !important;

    &.active {
      color: #495057 !important;
      background-color: #fff !important;
      border-bottom: none !important;
    }
  }
}

.main-content::v-deep .tab-content {
  padding: 20px 6px;
}

$split-info-bar-breakpoint: 1450px;

.info-bar {
  display: flex;
  align-items: start;
  margin-bottom: 2.5rem;
  justify-content: space-between;

  flex-direction: column;
  gap: 1rem;
  @media (min-width: $split-info-bar-breakpoint) {
    flex-direction: row;
    gap: max(10px, 2%);
  }
  @include media-breakpoint-down(sm) {
    align-items: center;
    margin-bottom: 0;
  }

  .status,
  .location {
    border-radius: 5px;
    min-width: 120px;
    height: 40px;
    font-weight: bold;
    padding: 0 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
      margin-top: 7px;
      width: 90px;
      min-width:0px;
    }
  }

  .sub {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    gap: normal;
    @media (min-width: $split-info-bar-breakpoint) {
      width: auto;
      gap: max(10px, 2%);
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 1rem;
      align-items: start;
    }
  }

  .status {
    background-color: #d4edfa;
    color: #0096db;
  }

  .location {
    background-color: #74788d;
    color: #f8f9fe;
  }
}

.time-elapsed {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    text-align: right;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
    text-align: left;
    }
  }
  span:last-of-type {
    width: auto;
  }
}

.next-step {
  display: flex;
  gap: 10px;
  span:first-of-type {
    text-align: right;
    width: min-content;
    @include media-breakpoint-down(sm) {
    text-align: left;
    }
  }
  span:last-of-type {
    font-weight: bold;
  }
}

.gap10 {
  gap: 10px;
}

.info-box {
  border: 1px solid #e4e4e4;
  background-color: #f8f9fe;
  padding: 0 8px;
}

.alerta {
  display: flex;
  gap: 10px;
  // padding: 10px;
  // height: fit-content;
  border-radius: 5px;
  white-space: nowrap;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  &.solicitado {
    border: 1px solid #f1b44c;
    background-color: #fffbf3;
  }
  &.aprobado {
    border: 1px solid #88f14c;
    background-color: #f9fff3;
  }
  &.rechazado {
    border: 1px solid #f14c4c;
    background-color: #fff3f3;
  }
}

.icon-printer {
  @include media-breakpoint-up(sm) {
    margin-left: 25px;
  }
}

::v-deep .tab-wrapper {
  overflow-x: auto;
  overflow-y: hidden;

  ul {
    overflow-x: visible;
  }
}
::v-deep .dropdown-menu.show {
  width: 250px;
}
.alerta-cursor{
  cursor: pointer;
}
</style>
