<template lang="pug">
div
  hr
  .form-card
    QuoteSummary(:quote="payload")

    .d-flex.justify-content-between.mt-4
      b-button.form-btn.secondary-btn(@click="goBack()" small variant="outline")
        | Anterior
      b-button.form-btn.primary-btn(@click="submit" small variant="samtek-yellow")
        | Enviar solicitud
    .d-flex.justify-content-center

    b-modal.hide-footer(
      v-model="showModal"
      no-close-on-backdrop
      title="Solicitud de Oportunidad de Venta Enviada"
      header-text-variant="darker-info"
    )
      .text-center.flex-col.gap-4
        font-awesome-icon(:icon="['fa','check-circle']" size="4x" color="#FECE2F")
        p.mb-0
          | Su cotización se ha enviado correctamente.
          br
          | Le enviaremos la respuesta al Email Ingresado
      template(#modal-footer='{ ok, cancel, hide }')
        .d-flex.justify-content-center.w-100
          b-button.form-btn.primary-btn.mb-3(variant='samtek-yellow' @click="finish()") Aceptar
</template>

<script>
import axios from '@/plugins/axios'
import QuoteSummary from '@/components/Quotations/ExternalQuotes/QuoteSummary.vue'
import { useExternalQuoteStore } from '@/pinia/api/useExternalQuoteStore'
import moment from 'moment'
import { mapActions } from 'pinia'

export default {
  name: 'ResumeQuotations',
  props: ['dataClient', 'form'],
  components: {
    QuoteSummary,
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    payload() {
      return {
        quote_client: {
          rut: this.dataClient.rut,
          first_name: this.dataClient.nombre,
          last_name: this.dataClient.apellido,
          region: this.dataClient.region,
          commune: this.dataClient.comuna,
          address: this.dataClient.direccion,
          phone: this.dataClient.telefono,
          email: this.dataClient.email
        },
        parts: this.form.parts.map((part) => ({
          category_id: part.category,
          comments: part.comments,
        })),
        device_serial_number: this.form.device.serial_number,
        device_picture: this.form.deviceImage ? this.form.deviceImage : null,
      }
    }
  },
  methods: {
    ...mapActions(useExternalQuoteStore, ['createExternalQuote']),
    goBack() {
      this.$emit('goBack')
    },
    async submit() {
      const data = new FormData()
      Object.entries(this.payload.quote_client).forEach(([key, value]) => {
        data.append(`quote_client.${key}`, value)
      })
      data.append('parts_data', JSON.stringify(this.payload.parts))
      data.append('device_serial_number', this.payload.device_serial_number)
      if (this.payload.device_picture) {
        data.append('device_picture', this.payload.device_picture)
      }
      console.log("this.dataClient ", this.dataClient);
      if(this.dataClient.token) {
        data.append('token', this.dataClient.token)
      }
      

      console.log("this.payload ", this.payload)
      

      const res = await axios.post('/api/oportunidadventa/oportunidad-completada', data) 
  
      if (res.status < 200 || res.status >= 300) {
        this.showModal = false
        return this.$bvToast.toast('Error al enviar la oportunidad de venta', {
          variant: 'danger',
        })
      }

      this.showModal = true
    },
    getFecha(fecha) {
      const date = moment(fecha).format('DD/MM/YYYY')
      return date
    },
    async finish() {
      this.$emit('created')
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/colors.scss';

::v-deep.form-card {
  .title {
    letter-spacing: 0px;
    color: #74788d;
    opacity: 1;
    font-weight: 600;
  }
  .title-data {
    color: #253590;
    font-weight: 700;
    font-size: 1.2em;
  }
  .details {
    color: var(--info);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .input-label {
    color: #74788d;
    font-weight: bold;
    font-size: 1em;
  }
  .display-data {
    font-size: 1em;
    margin-bottom: 20px;
  }
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: $samtek-black;
  }
}
.primary-btn {
  font-weight: 600;
}
.secondary-btn {
  border-color: $samtek-yellow;
  font-weight: 600;
}
</style>
