<template lang="pug">
  div
    b-form-select(
      v-model="filters.despacho"
      placeholder="Despacho"
      :options="dispatchOptions"
    )
</template>

<script>
import { useDashboardStore } from '@/pinia/views/useDashboardStore'
import { mapWritableState } from 'pinia'

export default {
  data() {
    return {
      dispatchOptions: [
        { value: null, text: 'Todos los despachos' },
        { value: 'ENVIO', text: 'Envio' },
        { value: 'RETIRO', text: 'Retiro' },
      ],
    }
  },
  beforeMount() {
    this.filters.despacho = null
  },
  async mounted () {
    await this.filters
    this.filters.despacho = null
  },
  computed: {
    ...mapWritableState(useDashboardStore, ['filters']),    
  },
}

</script>