<template lang="pug">
#PrepararNuevaCotizacion
  b-button(
    variant="primary"
    @click="openModal"
  )
    | Preparar Nueva Cotización
  //- Modal para confirmar nueva cotización
  b-modal.hide-footer(v-model="showModal" no-close-on-backdrop, title="Preparar Nueva Cotización")
    h5.center ¿Estás seguro de preparar una nueva cotización?
    template(#modal-footer='{ ok, cancel, hide }')
      b-button(size='sm' variant='primary' @click='prepararCotizacion' :disabled="loading") Si
        b-spinner(v-if="loading" small)
      b-button(size='sm' variant='primary' @click='hideModal' :disabled="loading") No
        b-spinner(v-if="loading" small)
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'PrepararNuevaCotizacion',
  data() {
    return {
      showModal: false,
      loading: false,
    }
  },
  props: {
    ovId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions('OVStore', ['prepareNewQuotation']),
    async prepararCotizacion() {
      this.loading = true
      const resp = await this.prepareNewQuotation({ id_oportunidad_venta: this.ovId })
      const messageTitle = resp.status === 200 ? 'Cotización preparada' : 'Error al preparar cotización'
      const message = resp.status === 200 ? 'La cotización ha sido preparada' : 'Ha ocurrido un error al preparar la cotización'
      const messageVariant = resp.status === 200 ? 'success' : 'danger'
      this.$emit('reload', true, messageTitle, message, messageVariant)
      this.loading = false
      this.hideModal()
    },
    openModal() {
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
  width: 100%;
}
</style>
