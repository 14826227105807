<template lang="pug">
div
  .loader(v-if="loading")
      b-spinner(label='Cargando...' variant="primary")
  div(v-else).print-container
    .only-print
      .d-flex.justify-content-between
        img( :src="imgUrl" width="85" height="64" ).mt-1.mb-1.d-block
        .membrete.text-right
          div (+562) 2651 7830
          div Schubert 46, San Joaquín, Región Metropolitana
      h2.print-title.mb-2
        | Resumen Orden de Trabajo {{ot.ot}}
      p( v-if="ot.recepcionista && ot.recepcionista.first_name" ).mb-1
        | Ingresador por {{`${ot.recepcionista.first_name} ${ot.recepcionista.last_name}`}}
      p Fecha de ingreso: {{ot.fecha_ingreso | beautyDate}}

    .grid-wrapper
      ClientData( v-if="ot.cliente" )
      TemporaryData( v-if="ot.datos_provisorios" )
      EquipmentData( v-if="ot.cliente && ot.equipo" )
      EnteredEquipment( v-if="ot.cliente && ot.equipo" )
    DocumentsTable.no-print
    .sign.only-print
      span ______________________________
      span Firma cliente

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ClientData from './ClientData.vue'
import TemporaryData from './TemporaryData.vue'
import EquipmentData from './EquipmentData.vue'
import EnteredEquipment from './EnteredEquipment.vue'
import DocumentsTable from './DocumentsTable.vue'

export default {
  name: 'DiagnoseForm',
  components: {
    ClientData,
    TemporaryData,
    EquipmentData,
    EnteredEquipment,
    DocumentsTable,
  },
  data() {
    const basePath = process.env.VUE_APP_SERVER_BASE || 'https://center.samtek.cl'
    return {
      loading: true,
      imgUrl: `${basePath}/logo_original.png`,
    }
  },
  async created() {
    if (this.ot) this.loading = false
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'printNoDisplay']),
  },
  filters: {
    beautyDate(fullDate) {
      //eslint-disable-line
      if (fullDate) {
        const date = moment(fullDate).format('DD/MM/YYYY')
        const time = moment(fullDate).format('HH:mm')
        return `${date} a las ${time}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.only-print {
  display: none;
}
.grid-wrapper {
  width: 100%;
  display: grid;

  grid-template-columns: repeat(7, minmax(100px, 240px));
  grid-gap: 14px 40px;

  $split-info-bar-breakpoint: 1449px;

  @media (max-width: $split-info-bar-breakpoint) {
    grid-template-columns: repeat(6, minmax(100px, 240px));
    grid-gap: 14px 32px;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(5, minmax(100px, 240px));
    grid-gap: 14px 32px;
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(2, minmax(100px, 300px));
    grid-gap: 14px 168px;
  }

  @include media-breakpoint-down(xs) {
    grid-template-columns: repeat(1, 100%);
    grid-gap: 14px normal;
  }

  &::v-deep {
    hr {
      margin-top: 8px;
      margin-bottom: 2px;
    }
    .span-full,
    .span-full-screen {
      grid-column: 1 / -1;
    }
    .span-2 {
      grid-column: span 2;
    }
  }
}
</style>
