import store from '@/store'
import checkRoles from '@/utils/permissions'

import Vue from 'vue';
import VueRouter from 'vue-router';
import FilteredResultsDashboard from '@/views/FilteredResultsDashboard.vue';
import ClientOT from '@/components/OT/ClientOT/ClientOT.vue';
import ConfirmChange from '@/components/OT/ClientOT/ConfirmChange.vue';
import Dashboard from '../views/Dashboard.vue';
import AskParts from '../views/RequestParts/AskParts.vue';
import RequestedPartsView from '../views/RequestParts/RequestedPartsView.vue';
import LoginView from '../views/LoginView.vue';
import InventarioView from '../views/Inventario/InventoryView.vue';
import MovimientosManualesView from '../views/Inventario/MovimientosManualesView.vue';
import IngresoInventarioView from '../views/Inventario/IngresoInventarioView.vue';
import Parts from '../views/Inventario/Parts.vue';
import CreateOTView from '../views/OTBackoffice/CreateOTView.vue';
import ListOTView from '../views/OTBackoffice/ListOTView.vue';
import ViewOTView from '../views/OTBackoffice/ViewOTView.vue';
// import OTView from '../views/OTView.vue';
// import AssignList from '../views/AssignOT/AssignList.vue';
// import DiagnoseOT from '../views/DiagnoseOT/DiagnoseOT.vue';
// import AssignTecnicoView from '../views/AssignTecnicoView.vue';
// import Bodega from '../views/Bodega.vue';
import ExcepcionesView from '../views/ExcepcionesView.vue'
import ClientOTView from '../views/ClientOTView.vue'
import Preingreso from '../components/OT/PreIngresoCliente/PreIngreso.vue'
import ExternalQuotesView from '../views/ExternalQuotesView.vue'
import QuotationsView from '../views/QuotationsView.vue'
import SalesOpportunityView from '../views/SalesOpportunityView.vue'
import PreIngressRegions from '../components/OT/PreIngresoCliente/PreIngressRegions.vue'
import AddQuoteClientView from '../components/Quotations/AddQuoteClientView.vue'
import AddOportunityClientView from '../components/SalesOpportunity/AddOportunityClientView.vue';
import HistorialPiezaView from '../views/HistorialPiezaView.vue'
import SummaryReport from '../views/Reports/SummaryReport.vue'
import UserReport from '../views/Reports/UserReport.vue'

Vue.use(VueRouter)

const dashboardRoles = ['Administrativo', 'Gerencia', 'Bodega', 'Supervisor', 'Técnico', 'Ventas']

export const routes = [
  // Públicas
  {
    path: '/login',
    component: LoginView,
    meta: {
      isPublic: true,
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/components/auth/Login.vue'),
        meta: {
          isPublic: true,
          fullScreen: true,
          menu: false, // define si va en menú lateral
        },
      },
      {
        path: 'retail-register',
        name: 'Registro Retail',
        component: () => import('@/components/auth/RetailRegister.vue'),
        meta: {
          isPublic: true,
          fullScreen: true,
          menu: false,
        },
      },
      {
        path: 'retail-reset-password',
        name: 'Reiniciar Contraseña Retail',
        component: () => import('@/components/auth/RetailResetPassword.vue'),
        meta: {
          isPublic: true,
          fullScreen: true,
          menu: false,
        },
      },
    ]
  },

  // Dashboard
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      menu: true,
      roles: dashboardRoles,
    },
  },
  {
    path: '/resultados-filtros-dashboard',
    name: 'ResultadosFiltrosDashboard',
    component: FilteredResultsDashboard,
    meta: {
      menu: false,
    },
  },
  // OT Backoffice - vistas para administrar ordenes de trabajo
  {
    path: '/crear-orden',
    name: 'Ingresar OT',
    component: CreateOTView,
    meta: {
      menu: true,
      roles: ['Administrativo', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/editar-orden/:order_id',
    name: 'Editar OT',
    component: CreateOTView,
  },
  {
    path: '/listar-ordenes',
    name: 'Trabajo en curso',
    component: ListOTView,
    props: true,
    meta: {
      menu: true,
      roles: ['Administrativo', 'Gerencia', 'Bodega', 'Supervisor', 'Técnico', 'Retail'],
    },
  },
  {
    path: '/orden/:order_id/',
    name: 'Orden de Trabajo',
    component: ViewOTView,
    meta: {
      roles: ['Supervisor', 'Técnico', 'Gerencia', 'Bodega', 'Administrativo'],
      allowRefresh: true,
    },
  },
  {
    path: '/orden/:order_id/pedir-partes',
    name: 'PedirPartes',
    component: AskParts,
    meta: {
      roles: ['Supervisor', 'Técnico', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/orden/:order_id/repuestos-pedidos',
    name: 'RespuestosPedidos',
    component: RequestedPartsView,
    meta: {
      roles: ['Administrativo', 'Supervisor', 'Técnico', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/excepciones/ot/:otId',
    name: 'Excepciones para ot',
    component: ExcepcionesView,
    meta: {
      roles: ['Administrativo', 'Supervisor', 'Técnico', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/crear-orden-preingreso/:otId',
    name: 'Completar Preingreso',
    component: CreateOTView,
    meta: {
      roles: ['Administrativo'],
    },
  },
  // Excepciones
  {
    path: '/excepciones',
    name: 'Excepciones',
    component: ExcepcionesView,
    meta: {
      menu: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  // Cotizaciones internas
  {
    path: '/cotizacionesInterna',
    name: 'Cotizaciones Internas',
    component: QuotationsView,
    meta: {
      menu: false,
      LinkDropPresupuestos: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  // Cotizaciones externas
  {
    path: '/cotizaciones-externas',
    name: 'Cotizaciones Externas',
    component: ExternalQuotesView,
    meta: {
      menu: false,
      LinkDropPresupuestos: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  // Oportunidad de venta
  {
    path: '/oportunidad-venta',
    name: 'Oprtunidad de ventas',
    component: SalesOpportunityView,
    meta: {
      menu: false,
      LinkDropPresupuestos: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/ingresar-presupuesto',
    name: 'Ingresar Presupuesto',
    component: AddQuoteClientView,
    meta: {
      LinkDropPublic: true,
      isPublic: true,
      fullScreen: true,
      menu: false,
    },
  },
  {
    path: '/ingresar-oportunidad',
    name: 'Ingresar Oportunidad',
    component: AddOportunityClientView,
    meta: {
      LinkDropPublic: true,
      isPublic: true,
      fullScreen: true,
      menu: false,
    },
  },
  // ClientOT
  {
    path: '/cliente',
    name: 'ClienteOT-login',
    component: ClientOTView,
    meta: {
      LinkDropPublic: true, // define si va en la lista Links Clientes
      isPublic: true,
      fullScreen: true,
      menu: false, // define si va en menú lateral
    },
  },
  {
    path: '/cliente/:otId/rut/:rut',
    name: 'ClienteOT',
    component: ClientOT,
    meta: {
      fullScreen: true,
      menu: false, // define si va en menú lateral
      isPublic: true,
    },
  },
  {
    path: '/orden/confirmar_cambio_retiro/:token',
    name: 'ConfirmChange',
    component: ConfirmChange,
    meta: {
      LinkDropPublic: false, // define si va en la lista Links Clientes
      fullScreen: true,
      menu: false, // define si va en menú lateral
      isPublic: true,
    },
  },
  // Pre ingreso
  {
    path: '/pre-ingreso',
    name: 'Pre-Ingreso Retiro',
    component: Preingreso,
    meta: {
      LinkDropPublic: true,
      fullScreen: true,
      menu: false, // define si va en menú lateral
    },
  },
  {
    path: '/pre-ingreso-regiones',
    name: 'Pre-Ingreso Envío',
    component: PreIngressRegions,
    meta: {
      LinkDropPublic: true,
      fullScreen: true,
      menu: false, // define si va en menú lateral
    },
  },
  // Inventario
  {
    path: '/inventario',
    name: 'Control de Inventario',
    component: InventarioView,
    meta: {
      menu: false,
      LinkDropInv: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/movimientos-manuales',
    name: 'Movimientos Manuales',
    component: MovimientosManualesView,
    meta: {
      menu: false,
      LinkDropInv: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/partes',
    name: 'Mantenedor partes',
    component: Parts,
    meta: {
      menu: false,
      LinkDropInv: true,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/inventario/historial-pieza/:partNumber/:id',
    name: 'Historial de pieza',
    component: HistorialPiezaView,
    meta: {
      menu: false,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },
  {
    path: '/inventario/ingreso-inventario/:tipo',
    name: 'Ingreso de inventario',
    component: IngresoInventarioView,
    meta: {
      menu: false,
      roles: ['Administrativo', 'Supervisor', 'Gerencia', 'Bodega'],
    },
  },

  // Reportes
  {
    path: '/reports/summary',
    name: 'Reporte Resumen',
    component: SummaryReport,
    meta: {
      menu: false,
      LinkDropReport: true,
    },
  },
  {
    path: '/reports/user',
    name: 'Reporte Usuarios',
    component: UserReport,
    meta: {
      menu: false,
      LinkDropReport: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const restoreUserFromLocalStorage = () => {
  console.log('restoreUserFromLocalStorage');
  const storedUser = JSON.parse(window.localStorage.getItem('user'))
  if (storedUser && storedUser.user.email) {
    store.commit('AuthenticationStore/SET_USER', { user: storedUser })
  }
}

router.beforeEach((to, from, next) => {
  if (from.name == null) restoreUserFromLocalStorage()
  const user = store.getters['AuthenticationStore/user']
  const { isPublic, roles } = to.meta
  const hasPerm = checkRoles({ roles, user })

  if (isPublic) {
    /** Vista Login y usuario autenticado: Se redirecciona al Dashboard */
    if (user && to.name === 'Login') {
      return next({ name: 'Dashboard' })
    }

  /** Vista privada y usuario NO autenticado: Se redirecciona al Login */
  } else if (!user) {
    return next({ name: 'Login' })

  /** Usuario sin permisos para acceder a la ruta */
  } else if (!hasPerm) {
    try {
      if (!checkRoles({ roles: dashboardRoles, user })) {
        return next({ name: 'Trabajo en curso' })
      }
      return next({ name: 'Dashboard' })
    } catch (err) {
      console.log('catched', err)
    }
  }
  next()
})

export default router
