<template lang="pug">
#NavBar()
  b-navbar(variant="light-primary" toggleable="lg").justify-content-start.base-navbar
    b-navbar-toggle( target="navbar-toggle-collapse" @click="openMenu = !openMenu").mr-3
    img(v-if="mostrar" src="../../assets/logo_original_samtek.png" style="height:20px; margin:5px").ml-0.align-self-center
    div.spacer

    div(v-if="openMenu && screenSize > breakpoints.sm && screenSize <= breakpoints.lg") 
      GenericSearchFilterBar(ref="searchBar").mt-1.ml-3

    b-collapse#navbar-toggle-collapse( is-nav )
      b-nav-item.item(
        v-for="route in routes.filter((r) => r.meta ? r.meta.menu : false)"
        :key="route.path"
        v-if="checkUserRoles(route.meta ? route.meta.roles : [])"
        :to="route.path"
        exact
      )
        span(@click="clearFilters(route.name)") {{ route.name }}

      b-nav-item-dropdown( text="Presupuesto" v-if="!isRetailUser" )
        b-dropdown-item(
          v-for="route in routes.filter((r) => r.meta ? r.meta.LinkDropPresupuestos : false )"
          v-if="checkUserRoles(route.meta ? route.meta.roles : [])"
          :to="route.path"
          :key="route.path"
          exact
        )
          span.drop-item(@click="clearFilters(route.name)") {{ route.name }}

      b-nav-item-dropdown( text="Inventario" v-if="!isRetailUser" )
        b-dropdown-item(
          v-for="route in routes.filter((r) => r.meta ? r.meta.LinkDropInv : false )"
          v-if="checkUserRoles(route.meta ? route.meta.roles : [])"
          :to="route.path"
          :key="route.path"
          exact
        )
          span.drop-item(@click="clearFilters(route.name)") {{ route.name }}

      b-nav-item-dropdown( text="Clientes" v-if="!isRetailUser" )
        b-dropdown-item(
          v-for="route in routes.filter((r) => r.meta ? r.meta.LinkDropPublic : false )"
          v-if="checkUserRoles(route.meta ? route.meta.roles : [])"
          :to="route.path"
          :key="route.path"
          exact
        )
          span.drop-item(@click="clearFilters(route.name)") {{ route.name }}

      b-nav-item-dropdown( text="Reportes" v-if="!isRetailUser" )
        b-dropdown-item(
          v-for="route in routes.filter((r) => r.meta ? r.meta.LinkDropReport : false )"
          v-if="checkUserRoles(route.meta ? route.meta.roles : [])"
          :to="route.path"
          :key="route.path"
          exact
        )
          span.drop-item(@click="clearFilters(route.name)") {{ route.name }}

      GenericSearchFilterBar(v-if="screenSize >= breakpoints.lg" ref="searchBar").mt-1
    
    .d-block.w-100(v-if="screenSize <= breakpoints.sm")
      GenericSearchFilterBar(ref="searchBar").mt-1

    div(
      v-if="screenSize > breakpoints.sm && screenSize <= breakpoints.lg"
      :class="{ 'ml-3': !openMenu, 'd-none': openMenu }"
    )
      GenericSearchFilterBar(ref="searchBar").mt-1

    .d-flex.ml-auto.righthand-items
      Notifications.ml-4.mt-2
      b-nav-item-dropdown(right).mr-n2
        template(v-slot:button-content)
          span#UserName {{ fullName }}
        b-dropdown-item(@click="handleLogout" link-class="sesion-item")
          span  Cerrar Sesión
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { routes } from '../../router'
import GenericSearchFilterBar from '../OT/ListOT/GenericSearchFilterBar.vue'
import checkRoles from '../../utils/permissions'
import Notifications from './Notifications.vue'

export default {
  components: {
    Notifications,
    GenericSearchFilterBar,
  },
  data() {
    return {
      fullName: {},
      mostrar: true,
      routes,
      breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
      },
      screenSize: window.innerWidth,
      openMenu: false,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.screenSize = window.innerWidth
    })
  },
  created() {
    this.getUser()
  },
  destroyed() {
    document.getElementById('app').style.marginLeft = '0'
    document.getElementById('app').style.transition = 'none'
  },
  methods: {
    ...mapActions('AuthenticationStore', ['logout']),
    handleResize() {
      this.screenSize = window.innerWidth;
    },
    getUser() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      if (this.user?.user?.retail_type) {
        this.fullName = `${user.first_name}`
      } else {
        this.fullName = `${user.first_name || 'N'} ${user.last_name || 'N'}`
      }
    },
    clearFilters (routeName) {
      console.log('clear filters', routeName)
      this.$refs.searchBar.clearSearcher()
      if (routeName === 'Trabajo en curso') {
        this.$parent.$children[2].$children[0].$children[0].$refs.techFilter.$parent.filters.tecnico = null
        this.$parent.$children[2].$children[0].$children[0].$refs.techFilter.$parent.filters.alertColor = []
        this.$parent.$children[2].$children[0].$children[0].$refs.techFilter.$parent.filters.fase = null
        this.$parent.$children[2].$children[0].$children[0].$refs.techFilter.$parent.filters.tipoCliente = null
        this.$parent.$children[2].$children[0].$children[0].$refs.techFilter.$parent.filters.tipoServicio = null
      }
    },
    handleLogout() {
      this.logout()
      this.$router.push({ name: 'Login' })
    },
    // show() {
    //   if (this.mostrar) {
    //     if (!this.inMobile()) {
    //       document.getElementById('app').style.marginLeft = '250px';
    //     }
    //     document.getElementById('menu').style.marginLeft = '250px';
    //     document.getElementById('app').style.transition = '0.3s';
    //     this.mostrar = false;
    //   } else {
    //     if (!this.inMobile()) {
    //       document.getElementById('menu').style.marginLeft = '-50px';
    //     }
    //     document.getElementById('menu').style.marginLeft = '-250px';
    //     document.getElementById('app').style.marginLeft = '0';
    //     document.getElementById('app').style.transition = '0.3s';
    //     this.mostrar = true;
    //   }
    // },
    checkUserRoles(roles) {
      return checkRoles({ roles, user: this.user })
    },
    inMobile() {
      return (
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) < 700
      )
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),

    isRetailUser() {
      return this.user?.user?.groups?.some((g) => g.name === 'Retail')
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/global.scss';

$sill-red-light: lighten($sill-red, 40%) !important;

#NavBar::v-deep {
  margin-bottom: 30px;
  .navbar-expand {
    box-shadow: 0px 3px 6px #00000029;
  }
  #UserName {
    color: $sill-grey;
    font-weight: bold;
  }
  #menu {
    z-index: 99;
    background: #fff;
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    padding: 20px;
    transition: 0.3s;
  }
  .btn-hide-menu {
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 16px;
  }
  .boton {
    position: relative;
    // background: transparent;
    // border-color: transparent;
    // &:hover {
    //   background: transparent;
    //   border-color: transparent;
    // }
    &:focus {
      outline: none;
    }
  }
  .contenido {
    font-size: 14px;
    height: 32px;
    margin: 90px 0px;
  }

  .nav-item {
    padding-left: 10px;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
    .nav-link {
      color: $sill-grey;
      margin-left: -18px;
    }
    &.active {
      background: $sill-red-light;
      .nav-link {
        color: $sill-red;
        font-weight: bold;
      }
    }
    &:hover {
      .nav-link {
        color: $sill-red;
        font-weight: bold;
      }
    }
  }
  li {
    list-style-type: none;
  }
  .imagen {
    width: 21px;
    margin-right: 10px;
    margin-left: 17px;
  }
  .sesion-item {
    color: $sill-red;
    font-size: 15px;
    &:active {
      background-color: #e9ecef;
      font-weight: bold;
    }
  }
  .drop-item {
    color: var(--info);
    font-size: 15px;
  }
}

.base-navbar {
  box-shadow: 0px 3px 6px #00000029;
}

.righthand-items {
  position: absolute;
  top: 8px;
  right: 16px;
}

.mt-08 {
  margin-top: 0.8rem !important;
}

.spacer {
  height: 48px;
}
</style>
