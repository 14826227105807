import axios from '../../plugins/axios'

export const listOvAll = (_,data) => {
  let searchByEstado;
  if (data.estado && data.estado !== 'Todas') {
  
    searchByEstado = `?search=${data.estado}&page=${data.page}`
  }else {
    searchByEstado = `?page=${data.page}`
  }
  const apiAddress = `api/oportunidadventa/get-ov${searchByEstado}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    return response.data 
  })
}

export const getEstadosOV = ({  }) => {
  const apiAddress = `api/oportunidadventa/get-estados-ov`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    return response.data 
  })
}

export const listOvActions = ({ commit }, ovId) => {
  const apiAddress = `api/oportunidadventa/get-ov-actions/${ovId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    console.log(response.data)
    commit('SET_OV_ACTIONS', { ovActions: response.data })
  })
}

export const getOvDetail = ({ commit }, ovId) => {
  const apiAddress = `api/oportunidadventa/get-ov/${ovId}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.get(apiAddress, config).then((response) => {
    commit('SET_OV_DETAIL', { ovDetail: response.data })
  })
}

export const acceptOv = (_, data) => {
  const apiAddress = `api/oportunidadventa/aceptar-oportunidad`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config).then((response) => response)
}

export const declineOv = (_, data) => {
  const apiAddress = `api/oportunidadventa/rechazar-oportunidad`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, data, config).then((response) => response)
}

export const patchOv = (_, id, data) => {
  const apiAddress = `api/oportunidadventa/patch-ov/${id}`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.patch(apiAddress, data, config).then((response) => response)
}

export const prepareNewQuotation = (_, { id_oportunidad_venta }) => {
  const apiAddress = `api/oportunidadventa/preparar-nueva-cotizacion`
  const { key } = JSON.parse(localStorage.getItem('user'))
  const config = {
    headers: { Authorization: `Token ${key}` },
  }
  return axios.post(apiAddress, { id_oportunidad_venta }, config).then((response) => response)
}
