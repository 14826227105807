<template lang="pug">
    div.text-right
      b-button(variant="button").btn-new-ov.mb-3
        | Registrar Nueva Oportunidad de Venta
      ListOV  
  </template>
  
  <script>
  import ListOV from '../components/OV/ListOV.vue'
  
  export default {
    name: 'SalesOpportunityView',
    components: {
      ListOV,
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .btn-new-ov {
    background-color: #FECE2F;
    border: none;
    color: black;
  }
  </style>
  