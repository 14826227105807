<template lang="pug">
  b-card.parts-card
    .d-flex.justify-content-between.mb-3
      .titles
        h4.title Solicitar Partes
        h4.title Orden de trabajo {{ot_id}}
    b-card.part-card
      .row.mb-3(v-for="(part, index) of parts" :key="index")
        .col-1.d-flex.justify-content-center.align-items-center
          .circle
            span.number {{index + 1}}
        .col-2
          label.part-number Part Number
          b-form-input(v-if="data" v-model="part.parte.partNumber" disabled)
          SILLAutocomplete(
            v-if="!data"
            @click.native="onclick(index)"
            :search="searchParts"
            displayAttribute="part_number"
            :placeholder="part ? part.parte.partNumber: 'Ej: AF3FC3F'"
            :onSubmit="onPartSelected"
            :searching="searching"
            @keyup.native="setPartNumber(index)"
            v-bind:key="index"
          )
        .col-2
          label.label Código
          VueBarcode(v-model="part.parte.partNumber" width=1 height=30 textAlign="center" fontSize="13")
        .col-2
          label.label Descripción
          b-form-input(v-model="part.parte.description")
        .col-2.mr-1
          label.label Allocated
          div.d-flex.justify-content-start
            div.mr-5
              input.mr-1(type="radio" :value="true" :name="index" v-model="part.allocated")
              label.label Si
            div
              input.mr-1(type="radio" :value="false" :name="index" v-model="part.allocated")
              label.label No
        .col-2
          label.label Real Location
          b-form-input(v-model="part.parte.realLocation")
        .col-1.d-flex.justify-content-center.align-items-center
          b-button(@click="quitarParte(index)" variant="danger" pill) x
      button(@click="agregarParte" v-if="parts.length < 50").ask-parts.mt-2 + Agregar repuesto
    button.mt-5.float-right.ask-parts(v-if="parts.length > 0 && data" @click="actualizarSolicitud") Actualizar
    button.mt-5.float-right.ask-parts(v-else-if="parts.length > 0" @click="solicitarPartes") Solicitar
    button.mt-5.float-right.ask-parts(v-if="parts.length > 0" @click="impresionPartes" style="margin-right:10px") Imprimir
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import VueBarcode from 'vue-barcode'
import SILLAutocomplete from '@/components/UI/SILLAutocomplete.vue'
import print from '../../utils/print'

export default {
  name: 'AskPartsForm',
  components: { SILLAutocomplete, VueBarcode },
  props: ['ot_id', 'data'],
  data() {
    return {
      parts: [],
      searching: false,
      selectedPart: null,
      inputText: null,
      ot_data: null,
    }
  },
  async created() {
    this.getAllUsers()
    if (this.data) {
      this.parts = this.data
    }
    await this.getOT(this.ot_id)
  },
  methods: {
    ...mapActions('Diagnosticos', [
      'askParts',
      'searchByPartNumber',
      'updateRequests',
    ]),
    ...mapActions('Warehouse', ['getAllUsers']),
    ...mapActions('Notifications', ['sendNotification']),
    ...mapActions('OTStore', ['getOT']),
    impresionPartes() {
      // método para probar la impresión
      const now = moment().format('DD/MM/YYYY H:mm:ss')
      print(this.ot_id, this.parts, this.ot, now)
    },
    agregarParte() {
      this.parts.push({
        ot: this.ot_id,
        parte: {
          id: null,
          partNumber: null,
          description: null,
          realLocation: null,
        },
        allocated: null,
      })
    },
    async solicitarPartes() {
      const resp = await this.askParts({ otId: this.ot_id, parts: this.parts })
      if (resp && resp.status === 201) {
        this.$bvToast.toast(`${resp.data}`, {
          variant: 'success',
        })
        const now = moment().format('DD/MM/YYYY H:mm:ss')
        print(this.ot_id, this.parts, this.ot, now)
        const users = this.allUsers.filter((u) =>
          u.groups.find((g) => g.name === 'Bodega')
        )
        const userIds = users.map((u) => u.id)
        const anyAllocated = this.parts.find((p) => p.allocated === true)
        if (anyAllocated) {
          const data = {
            recipients: userIds,
            ot: this.ot_id,
            message: 'Partes solicitadas a bodega samtek',
            send_by_email: false,
            type_message: 2,
          }
          const rsp = await this.sendNotification(data)
          console.log(rsp)
        }
        this.parts = []
      }
    },
    async searchParts(partNumber) {
      this.inputText = partNumber
      if (partNumber.length > 2) {
        this.searching = true
        const resp = await this.searchByPartNumber({ partNumber })
        this.searching = false
        let partes = resp.data
        if (resp.data.length === 0) {
          partes = [
            {
              description: null,
              id: null,
              part_number: partNumber,
              real_location: null,
            },
          ]
        }
        return partes
      }
    },
    quitarParte(index) {
      this.parts.splice(index, 1)
    },
    onPartSelected(partSelected) {
      this.selectedPart = partSelected
    },
    setPartNumber(index) {
      this.parts[index].parte.partNumber = this.inputText
    },
    onclick(index) {
      if (this.selectedPart) {
        this.parts[index].parte.id = this.selectedPart.id
        this.parts[index].parte.description = this.selectedPart.description
        this.parts[index].parte.partNumber = this.selectedPart.part_number
        this.parts[index].parte.realLocation = this.selectedPart.real_location
      }
      this.selectedPart = null
    },
    async actualizarSolicitud() {
      const resp = await this.updateRequests(this.parts)
      console.log(resp)
    },
  },
  computed: {
    ...mapGetters('Warehouse', ['allUsers']),
    ...mapGetters('OTStore', ['ot']),
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  border: 1px solid #cbcbcb;
  opacity: 1;
}
.col-1 {
  flex: 0 0 5.333333%;
  max-width: 5.333333%;
}
.number {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #5c5fc1 0% 0%;
  opacity: 1;
  border-radius: 50%;
}
.parts-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  height: 85vh;
}
.part-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}
label {
  font-weight: bold;
}
.label {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
}
.part-number {
  color: #253590;
}
.title {
  letter-spacing: 0px;
  color: #74788d;
  opacity: 1;
  font-weight: 600;
}
.ask-parts {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 175px;
  height: 45px;
  border-radius: 5px;
  font-weight: 600;
}
</style>
