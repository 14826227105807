<template lang="pug">
b-row
  b-col( cols="12" lg="6" )
    vue-typeahead-bootstrap(
      v-model="model"
      :data="data"
      :serializer="serializer"
      placeholder="Ej: 76872144-0"
      @input="$emit('rut-typed')"
      @hit="(item) => $emit('rut-selected', item)"
    )
  b-col( cols="12" lg="6" ).mt-3.mt-lg-0
    b-button( v-if="showCreateBtn" @click="$emit('create-customer')" ).w-100 Crear
</template>

<!-- TODO:  traer más lógica desde el clientform en lugar
            de pasar para arriba los eventos -->

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
// vue-typeahead-bootstrap-custom-eleva

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    data: Array,
    value: String,
    showCreateBtn: Boolean,
  },
  data: () => ({}),
  computed: {
    model: {
      get() {
        return this.value?.replace(/\./g, '')
      },
      set(v) {
        this.$emit('input', v)
        // Validacion de campo RUT (En caso de volver a solicitarse )
        // const rutLimpio = v.replace(/[^0-9kK]/g, '');
        // if (rutLimpio.length < 2) return rutLimpio;
        // this.$emit('input', rutLimpio)

      },
    },
  },
  methods: {
    serializer: (cliente) => {
      if (cliente.tipo_cliente === 'Persona' && cliente.persona) {
        return `${cliente.persona.rut}  /  ${cliente.persona.nombre}`
      }
      if (cliente.tipo_cliente === 'Empresa' && cliente.empresa) {
        return `${cliente.empresa.rut}  /  ${cliente.empresa.razon_social}`
      }
      return 'AAA'
    }
  },
}
</script>

<style lang="scss" scoped></style>
