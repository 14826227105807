<template lang="pug">
#AddInventoryForm
  div(style="display: flex; justify-content: end;")
    b-button.mb-3(variant="button" :href="fileUrl" download)
      b-icon.mr-1(icon="cloud-download" variant="ligth")
      | Descargar Plantilla
  .container-list
    b-card(border-variant="light")
      .d-flex.justify-content-between.mb-3.align-items-end
        h5.title {{title}}
        b-button(variant="button" v-b-modal.file-modal)
          b-icon.mr-1(icon="file-earmark-arrow-up-fill" variant="ligth")
          |  Carga Masiva
      b-modal#file-modal(title="Seleccionar archivo" hide-footer)
        b-form-file(
          v-model="file"
          accept=".xlsx, .xls, .csv"
          placeholder="Seleccionar archivo"
          drop-placeholder="Arrastre el archivo aquí"
          browse-text="Buscar"
        )
        .d-flex.justify-content-end.mt-5
          b-button(variant="button" @click="handleFileUpload") Cargar
      b-table(
        :items="tableItems",
        :fields="fields",
      )
        template(#cell(part_number)='value')
          vue-typeahead-bootstrap(
            v-model="tableItems[value.index].part_number"
            @input="handleInput(value.index, $event)"
            :data="data"
            :serializer="p => p.part_number"
            placeholder="Ingresar Código"
            @hit="(item) => handleHit(item, value.index)"
            :state="$v.$dirty? !$v.tableItems.$each[value.index].part_number.$invalid : null"
            :disabled="Boolean(tableItems[value.index].id)"
          )
          .errors(v-if="$v.$dirty")
            b-form-invalid-feedback(:state="$v.tableItems.$each[value.index].part_number.required")
              | Campo requerido
        template(#cell(quantity)='value')
          b-form-group(:invalid-feedback="!$v.tableItems.$each[value.index].quantity.required ? 'Campo requerido' : 'Debe ser mayor a 0'")
            b-form-input(
              v-model="value.item.quantity"
              type="number" min="1" :state="$v.$dirty? !$v.tableItems.$each[value.index].quantity.$invalid : null",
              :disabled="!(tableItems[value.index].id)"
            )
        template(#cell(existingQuantity)='value', v-if="existingQuantityAttribute")
          b-form-input(v-model="value.item[existingQuantityAttribute]" disabled)
        template(#cell(real_location)='value')
          b-form-input(v-model="value.item.real_location" disabled)
        template(#cell(description)='value')
          b-form-input(v-model="value.item.description" disabled)
        template(#cell(delete)='value')
          b-button(variant="link" @click="handleDeleteRow")
            b-icon.mr-1(icon="trash-fill" variant="danger")
      .d-flex.justify-content-between(v-if="$route.params.tipo !== 'drop'")
        b-button(variant="link" @click="handleAddRow")
          b-icon.mr-1(icon="plus-square-fill" )
          |  Agregar Pieza
        b-button(variant="link" @click="handleDeleteAll" v-if="tableItems.length > 0")
          div(style="color: var(--danger)") Eliminar todas las piezas
      .d-flex.flex-row-reverse
        b-button.px-5.mt-5(variant="estado-green" @click="handleSave" v-if="tableItems.length > 0" :disabled="loading") Guardar
</template>
<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { mapActions, mapGetters } from 'vuex'
import { required, minValue } from 'vuelidate/lib/validators'

const basePath = process.env.VUE_APP_SERVER_BASE || 'https://center.samtek.cl'

export default {
  name: 'AddInventoryForm',
  components: {
    VueTypeaheadBootstrap,
  },
  data() {
    return {
      fields: [
        { key: 'part_number', label: 'Código de la Pieza' },
        { key: 'quantity', label: 'Cantidad' },
        { key: 'existingQuantity', label: 'Cantidad existente' },
        { key: 'real_location', label: 'Ubicación Real' },
        { key: 'description', label: 'Descripción' },
        { key: 'delete', label: '' },
      ],
      tableItems: [],
      search: '',
      data: [],
      index: null,
      file: null,
      loading: false,
      fileUrl: `${basePath}/plantilla_mantenedor.xlsx`,
    }
  },
  validations: {
    tableItems: {
      $each: {
        part_number: {
          required,
        },
        quantity: {
          required,
          minValue: minValue(1),
        },
      },
    },
  },
  methods: {
    ...mapActions('Diagnosticos', ['searchByPartNumber', 'searchPartsByState']),
    ...mapActions('Warehouse', ['saveInventory', 'saveInventoryExcel']),
    handleAddRow() {
      this.tableItems.push({
        id: null,
        part_number: null,
        quantity: null,
        real_location: '',
        description: '',
        tipo: this.$route.params.tipo,
      })
    },
    handleInput(index, value) {
      this.index = index
      this.search = value
    },
    handleDeleteRow(index) {
      this.tableItems.splice(index, 1)
    },
    handleHit(value, index) {
      this.tableItems[index].id = value.id
      this.tableItems[index].part_number = value.part_number
      this.tableItems[index].real_location = value.real_location
      this.tableItems[index].description = value.description
      this.tableItems[index].cantidad_drop = value.cantidad_drop
      this.tableItems[index].cantidad_malos = value.cantidad_malos
      this.tableItems[index].cantidad_buenos = value.cantidad_buenos
      this.search = ''
    },
    handleDeleteAll() {
      this.tableItems = []
    },
    async handleSave() {
      this.loading = true;
      if (this.tableItems.filter((ti) => ti?.id === null).length > 0) {
        this.$bvToast.toast('Algunas de las piezas especificadas no existe', {
          variant: 'danger',
        });
      }
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        const res = await this.saveInventory(this.tableItems)
        if (res.status === 200) {
          this.$bvToast.toast('Inventario ingresado exitosamente', {
            variant: 'success',
          })
          setTimeout(
            () => this.$router.push({ name: 'Control de Inventario' }),
            1000
          )
        } else {
          const responseMsj = res.data.substring(
            res.data.indexOf("'") + 1,
            res.data.indexOf("'", res.data.indexOf("'") + 1)
          )
          this.$bvToast.toast(responseMsj, {
            variant: 'danger',
          })
        }
      }
      this.loading = false
    },
    async handleFileUpload() {
      const file_data = new FormData()
      file_data.append('file', this.file)
      file_data.append('tipo_movimiento', this.$route.params.tipo)
      const response = await this.saveInventoryExcel(file_data)
      console.log(response)
      if (response.status === 200) {
        console.log(response.data)
        this.$bvToast.toast('Inventario ingresado.', {
          variant: 'success',
        })
        setTimeout(
          () => this.$router.push({ name: 'Control de Inventario' }),
          1000
        )
      } else {
        const responseMsj = response.data.substring(
          response.data.indexOf("'") + 1,
          response.data.indexOf("'", response.data.indexOf("'") + 1)
        )
        this.$bvToast.toast(responseMsj, {
          variant: 'danger',
        })
      }
    },
    async setDefault(state) {
      await this.searchPartsByState({ state })
      this.index = 0
      this.filteredParts.forEach((part) => {
        this.handleAddRow()
        this.handleHit(part, this.index)
        this.index += 1
      })
    },
  },
  computed: {
    ...mapGetters('Diagnosticos', ['filteredParts']),
    title() {
      const titulo = this.$route.params.tipo
      if (titulo === 'drop') {
        return 'Rebaja de DROP'
      }
      if (titulo === 'scrap') {
        return 'Scrap'
      }
      if (titulo === 'rtv') {
        return 'RTV'
      }
      if (titulo === 'slow') {
        return 'SLOW'
      }
      return 'Ingreso de Inventario'
    },
    existingQuantityAttribute() {
      const titulo = this.$route.params.tipo
      if (titulo === 'drop') {
        return 'cantidad_drop'
      }
      if (titulo === 'scrap' || titulo === 'rtv') {
        return 'cantidad_malos'
      }
      if (titulo === 'slow') {
        return 'cantidad_buenos'
      }
      return 'cantidad_buenos'
    },
  },
  watch: {
    async search(value) {
      if (value.length > 2) {
        const { data } = await await this.searchByPartNumber({
          partNumber: value,
        })
        console.log(data.results)
        this.data = data.results
      }
    },
  },
  created() {
    const titulo = this.$route.params.tipo
    if (titulo === 'drop' || titulo === 'scrap' || titulo === 'rtv') {
      const state = titulo === 'drop' ? titulo : 'bad'
      this.setDefault(state)
    }
  },
}
</script>
<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
}
</style>
