import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { Fragment } from 'vue-frag'
import vSelect from 'vue-select'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueMoment from 'vue-moment'
import moment from 'moment'
import * as Sentry from "@sentry/vue"
import App from './App.vue'
import router from './router'
import store from './store'
import pinia from './plugins/pinia'

import './registerServiceWorker'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import './utils/filters'

// Path of the server. Example: https://server.com
const basePath = process.env.VUE_APP_SERVER_BASE || 'https://center.samtek.cl'
const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    `${basePath}/print.css`,
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
  ],
}

const sentryPath = process.env.VUE_APP_SENTRY_DSN

if (sentryPath) {
  Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [],
	});
}


Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueHtmlToPaper, options)
Vue.use(VueMoment, { moment })

Vue.component('v-select', vSelect)
Vue.component('Fragment', Fragment)

new Vue({
  router,
  store,
  pinia,
  render: (h) => h(App),
}).$mount('#app')
