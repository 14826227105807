import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ClientStore = {
  namespaced: true,
  state: {
    ovActions: [],
    ovDetail: {},
  },
  getters,
  mutations,
  actions,
}

export default ClientStore
