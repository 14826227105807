<template lang="pug">
  .login
    img.logo(src="../../assets/logo_original_samtek.png")
    .card-login
      .subtitle
        h4 Bienvenido al
        .under-h4 Servicio Técnico de Samtek
        br
        | Por favor ingresa tu número de orden para consultar el estado de tu equipo.
      .no-ot(v-show="errors.noFoundOT")
        .text-no-ot.ml-1 No tenemos resultados para el numero ingresado
      .form-group
        label.label-ot RUT
        b-form-input(
          v-model="formatedRut"
          placeholder="Ingresar tu RUT"
        )
      .form-group
        label.label-ot Número de Orden de Trabajo
        b-form-input(
          v-model="OTNumber"
          placeholder="Ingresar OT"
          type="number"
        )
      .d-flex.justify-content-center
        b-button.samtek-btn( @click="onSubmit") Consultar
    Footer.footer-client
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Footer from '../UI/Footer.vue';

export default {
  name: 'OTLogin',
  components: {
    Footer,
  },
  data() {
    return {
      OTNumber: null,
      rut: null,
      errors: {
        noFoundOT: false,
      },
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'statusRequest']),
    formatedRut: {
      get() {
        return this.rut?.replace(/\./g, '')
      },
      set(v) {
        // this.rut = v?.replace(/\./g, '')
        const rutLimpio = v.replace(/[^0-9kK]/g, '');

        // asilar el cuerpo del dígito verificador
        const cuerpo = rutLimpio.slice(0, -1);
        const dv = rutLimpio.slice(-1).toUpperCase();

        if (rutLimpio.length < 2) return rutLimpio;

        // colocar los separadores de miles al cuerpo
        let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');

        cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, ''); // eslint-disable-line

        this.rut = `${cuerpoFormatoMiles.replace(/[^0-9kK]/g, '')}-${dv}`;
      },
    },
  },
  methods: {
    ...mapActions('OTStore', ['getOTPublic']),
    ...mapMutations('OTStore', ['SET_STATUS']),
    async onSubmit() {
      const OT = parseInt(this.OTNumber, 10)
      await this.getOTPublic({ otId: OT, rut: this.rut })
      if (this.statusRequest || this.OTNumber === null || this.OTNumber === '') {
        this.SET_STATUS(null)
        this.errors.noFoundOT = true
        return
      }
      this.$router.push(`./cliente/${OT}/rut/${this.rut}`)
      this.errors.noFoundOT = false
    },
  },
  mounted () {
    const parentContainer = document.querySelector('.login-view')
    parentContainer.style['background-image'] = `url("fondo_samtek.png")`
    parentContainer.style['background-color'] = '#F5F6FA'
    parentContainer.style['background-size'] = 'contain'
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/colors.scss';

.login {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    padding-bottom: 3rem;
    margin-top: 3rem;
    width: 10%;
    /* min-width: 240px; */
    min-width: 200px;
  }
  .card-login {
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px;
    opacity: 1;
    padding: 35px 45px;
    width: 470px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (width: 768px) {
      width: 430px;
      //height: 480px;
      margin-left: -100px;
    }
    @media (width: 1024px) {
      width: 430px;
      //height: 361px;
      margin-left: -55px;
    }
  }
  .subtitle {
    color: #525252;
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;
    h4 {
      /* color: $sill-blue; */
      color: #23201C;
      font-size: 18px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    .under-h4 {
      /* color: $sill-blue; */
      color: #23201C;
      font-size: 18px;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
      line-height: 120%;
    }
  }
  .icon-container {
    position: absolute;
    margin-left: 15px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  input {
    height: 50px;
  }
  .boton {
    margin: 20px 0;
    background-color: $sill-red;
    border-color: $sill-red;
    font-size: 15px;
    span {
      margin-left: 5px;
    }
  }
}
.no-ot {
  background: #fee9e9 0% 0% no-repeat padding-box;
  border: 1px solid #f46a6a;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #f46a6a;
  opacity: 1;
  padding: 0.5em;
  position: relative;
  top: -1.5em;
}
.text-no-ot {
  font-size: 1em;
}
::v-deep .alert-danger {
  color: #f46a6a;
  background-color: #fee9e9;
  border-color: #f46a6a;
}
::v-deep .form-group label {
  /* color: #253590; */
  color: #23201C;
  font-weight: bold;
}
::v-deep .btn-secondary {
  background-color: #FECE2F;
  border-color: #FECE2F;
}
::v-deep .btn-button {
  background-color: #FECE2F !important;
  border-color: #FECE2F !important;
  color: #23201C !important;
}
::v-deep .btn-button:hover {
  background-color: #e5b92a !important;
  border-color: #e5b92a !important;
  color: #23201C !important;
}
::v-deep .login input {
  padding-left: 0px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.footer-client {
  /* position: absolute; */
  bottom: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  /* text-wrap-mode: nowrap; */
  text-align: center;
}
</style>
