<template lang="pug">
#OTForm
    OTSection(
    step="03"
    v-if="equipment && equipment.id"
    title="Ingreso y descripción de la falla"
    :open="true"
    :section="'ot'"
    )
      b-container

        //- Sección colapasada
        div(v-if="!collapseOT && ot")
          b-form-group
            div
              label(class ="client-form-inputs" for="input-name") OT:
              slot {{ ' ' + ot.ot }}
            div
              label(class ="client-form-inputs" for="input-name") Tipo de ingreso:
              slot {{ ' ' + ot.tipo_servicio.nombre }}

        b-collapse(v-model="collapseOT" id="collapse-ot-section" visible)
          b-form
            b-row
              b-col
                div(v-if="!ot")
                  h5(class="client-header") Folio OT: {{ ot.ot || 'Sin asignar' }}
                div(v-else-if="form.ot")
                  h5(class="client-header") Folio OT: {{ form.ot }}

            //----------------------------------------------------------------
            b-row
              b-col(cols="12" md="4")
                b-form-group
                  label(class="client-form-inputs" for="input-servicio") Tipo de Servicio
                  b-form-select(
                      id="input-servicio"
                      v-model="form.tipo_servicio"
                      :options="serviceOptions"
                      :state="$v.form.tipo_servicio.$dirty? !$v.form.tipo_servicio.$invalid : null"
                      @input="$v.form.tipo_servicio.$touch()"
                  )
                    .errors(v-if="$v.form.tipo_servicio.$dirty")
                      b-form-invalid-feedback(:state="$v.form.tipo_servicio.required")
                          | Campo requerido

              b-col(cols="12" md="4")
                b-form-group
                  label(class="client-form-inputs" for="input-others") Otros Accesorios
                  b-form-input(
                    id="input-others"
                    v-model="form.otros_accesorios"
                    :state="$v.form.otros_accesorios.$dirty ? !$v.form.otros_accesorios.$invalid : null"
                    @input="$v.form.otros_accesorios.$touch()"
                  )
                  .errors(v-if="$v.form.otros_accesorios.$dirty")
                    b-form-invalid-feedback(:state="$v.form.otros_accesorios.required")
                        | Campo requerido

              b-col(v-if="client && client.tipo_cliente === 1")
                b-form-group
                  label(class="client-form-inputs" for="input-failClientReport") Despacho
                  b-select(v-model="form.despacho", :options="optionsDespacho" id="select-despacho")
                  .errors(v-if="$v.form.despacho.$dirty")
                    b-form-invalid-feedback(:state="$v.form.despacho.required")
                      | Campo requerido

            //----------------------------------------------------------------
            b-row
              b-col(cols="12" md="6")
                b-form-group
                  label(class="client-form-inputs" for="input-observations") Observaciones
                  b-form-textarea(
                    id="input-observations"
                    v-model="form.observaciones"
                    :state="$v.form.observaciones.$dirty ? !$v.form.observaciones.$invalid : null"
                    @input="$v.form.observaciones.$touch()"
                  )
                  .errors(v-if="$v.form.observaciones.$dirty")
                    b-form-invalid-feedback(:state="$v.form.observaciones.required")
                      | Campo requerido

              b-col(cols="12" md="6")
                b-form-group
                  label(class="client-form-inputs" for="input-failClientReport") Reporte de Falla del Cliente
                  b-form-textarea(
                    id="input-failClientReport"
                    v-model="form.reporte_falla_cliente"
                    :state="$v.form.reporte_falla_cliente.$dirty? !$v.form.reporte_falla_cliente.$invalid : null"
                    @input="$v.form.reporte_falla_cliente.$touch()"
                  )
                  .errors(v-if="$v.form.reporte_falla_cliente.$dirty")
                    b-form-invalid-feedback(:state="$v.form.reporte_falla_cliente.required")
                      | Campo requerido

            //----------------------------------------------------------------
            b-row(v-if="form.tipo_servicio=== 1 || form.tipo_servicio=== 2||form.tipo_servicio=== 3")
              b-col(cols="12" md="4")
                b-form-group
                  label(class="client-form-inputs" for="input-boleta") Número de boleta
                  b-form-input(
                    id="input-n-boleta"
                    v-model="form.numero_boleta"
                    :state="$v.form.numero_boleta.$dirty ? !$v.form.numero_boleta.$invalid : null"
                    placeholder="Ingrese número de boleta"
                )
                .errors(v-if="$v.form.numero_boleta.$dirty")
                    b-form-invalid-feedback(:state="$v.form.numero_boleta.required")
                    | Campo requerido

              b-col(cols="12" md="4")
                b-form-group
                  label(class="client-form-inputs" for="input-fecha_compra") Fecha de compra
                  b-form-datepicker(
                    id="input-purchaseDate"
                    v-model="form.fecha_de_compra"
                    locale="es-CL"
                    :state="$v.form.fecha_de_compra.$dirty ? !$v.form.fecha_de_compra.$invalid : null"
                    :max="new Date()"
                    select-variant="primary"
                    placeholder="Ingrese una fecha"
                )
                .errors(v-if="$v.form.fecha_de_compra.$dirty")
                    b-form-invalid-feedback(:state="$v.form.fecha_de_compra.required")
                    | Campo requerido

              b-col(cols="12" md="4")
                b-form-group
                  b-form-checkbox.mt-4.pt-2(
                    id="checkbox-need3x3"
                    v-model="form.need3x3"
                  ) ¿Cliente exige cambio o 3x3?

            //----------------------------------------------------------------
            b-row
              b-col(cols="12" md="6" v-if="client && client.tipo_cliente === 2")
                b-form-group
                    label(class="client-form-inputs" for="input-codigoRetail") Codigo Retail
                    b-form-input(
                        id="input-codigoRetail"
                        v-model="form.codigo_retail"
                    )
              b-col( cols="12" md="6" v-if="client && client.tipo_cliente === 2" )
                b-form-group
                  label(class="client-form-inputs" for="input-guia_ingreso") Guia de Despacho
                  b-form-input(
                    iD="input-guia_ingreso"
                    v-model="form.guia_despacho_ingreso"
                    :state="$v.form.guia_despacho_ingreso.$dirty ? !$v.form.guia_despacho_ingreso.$invalid : null"
                    @input="$v.form.guia_despacho_ingreso.$touch()"
                  )
                  .errors(v-if="$v.form.guia_despacho_ingreso.$dirty")
                      b-form-invalid-feedback(:state="$v.form.guia_despacho_ingreso.required")
                          | Campo requerido

            //----------------------------------------------------------------
            b-row
              b-col(cols="12" md="6", v-if="client && client.tipo_cliente === 1 && form.tipo_servicio !== 4")
                b-form-group
                  label(class="client-form-inputs" for="input-sucursal") Tienda
                  b-input-group
                    b-select(
                      v-model="form.sucursal",
                      id="input-sucursal",
                      :state="$v.form.sucursal.$dirty ? !$v.form.sucursal.$invalid : null"
                    )
                      b-form-select-option(v-for="empresa in empresas", :value="empresa.id") {{ empresa.razon_social }}
                    b-input-group-append
                      b-button(
                        variant="primary"
                        @click="showClienteEmpresaModal = true"
                      ) Nueva
                      ClienteEmpresaModal(v-model="showClienteEmpresaModal" @created="handleNewTienda")
                .errors(v-if="$v.form.sucursal.$dirty")
                    b-form-invalid-feedback(:state="$v.form.sucursal.required")
                      | Campo requerido

              b-col(v-if="form.tipo_servicio === 4" cols="12" md="6")
                b-form-group
                  label(class="client-form-inputs" for="input-cotizacion") Número de Presupuesto
                  b-form-input(
                      id="input-cotizacion"
                      :state="$v.form.numero_cotizacion.$dirty ? !$v.form.numero_cotizacion.$invalid : null"
                      v-model="form.numero_cotizacion"
                  )
                  .errors(v-if="$v.form.numero_cotizacion.$dirty")
                    b-form-invalid-feedback(:state="$v.form.numero_cotizacion.numeric")
                        | Solo puedes ingresar numeros

            //----------------------------------------------------------------
            b-row
              b-col
                b-form-group
                  label(class="client-form-inputs" ) Documentos
                b-row
                  b-col(cols='12' md='4' v-if="boletaUrl")
                    h6.input-label Boleta:
                    .flex-row.align-items-center.gap-1(v-if="boletaType === 'pdf'")
                      b-icon.device-icon.h3(icon="file-pdf" variant="black")
                      p {{ boletaName.split('/').pop() }}
                    img.device-image(v-else :src="boletaUrl" alt="Imagen Boleta")
                UploadFile( ref="upload" :attachmentType="ARCHIVO_ADJUNTO_TIPO.INGRESO")

            //----------------------------------------------------------------
            b-row
              b-col
                b-button.btn-create.mr-2(@click="onSubmit", :disabled="loading")
                  | {{ !form.ot ? 'Crear' : ( ot.estado && ['Pre ingreso', 'Pre ingreso regiones'].includes(ot.estado.estado.nombre)  ? 'Completar Pre-Ingreso' : 'Editar')}}
</template>

<script>
import { WebCam } from 'vue-web-cam';
import { required, numeric, requiredIf } from 'vuelidate/lib/validators';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ARCHIVO_ADJUNTO_TIPO } from '@/utils/enums'
import OTSection from './OTSection.vue';
import UploadFile from './UploadFile.vue';
import ClienteEmpresaModal from './ClienteEmpresaModal.vue';

export default {
  components: {
    'vue-web-cam': WebCam,
    OTSection,
    UploadFile,
    VueTypeaheadBootstrap,
    ClienteEmpresaModal,
  },
  name: 'OTForm',
  validations: {
    form: {
      tipo_servicio: { required },
      observaciones: { required },
      otros_accesorios: { required },
      reporte_falla_cliente: { required },
      guia_despacho_ingreso: { required: function () { return this.client.tipo_cliente === 2 ? !!this.form.guia_despacho_ingreso : true; } }, // eslint-disable-line
      numero_cotizacion: { numeric },
      sucursal: { required: function () { return this.client.tipo_cliente === 1 && (this.form.tipo_servicio === 1 || this.form.tipo_servicio === 2 || this.form.tipo_servicio === 3) ? !!this.form.sucursal : true; } }, // eslint-disable-line
      fecha_de_compra: { required: function () { return this.form.tipo_servicio === 1 || this.form.tipo_servicio === 2 || this.form.tipo_servicio === 3 ? !!this.form.fecha_de_compra : true; } }, // eslint-disable-line
      numero_boleta: { required: function () { return this.form.tipo_servicio === 1 || this.form.tipo_servicio === 2 || this.form.tipo_servicio === 3 ? !!this.form.fecha_de_compra : true; } }, // eslint-disable-line
      despacho: { required: requiredIf(function () { return this.client.tipo_cliente === 1 } ) }, // eslint-disable-line
    },
  },
  data() {
    return {
      openCamera: false,
      collapseDocuments: false,
      collapseOT: true,
      deviceId: null,
      devices: [],
      camera: null,
      despacho: '',
      // fotos: [],
      searching: false,
      selectedStore: null,
      loading: false,
      showClienteEmpresaModal: false,
      boletaUrl: null,
      boletaType: null,
      boletaName: null,
      // tiendaQuery: null,
      // tiendaList: [],
      // fieldsFotos: [
      //   { key: 'file', label: '#' },
      //   { key: 'preview', label: 'Vista previa' },
      //   { key: 'actions', label: 'Acciones' },
      // ],
      form: {
        rma: null,
        codigo_retail: null,
        tipo_servicio: null,
        observaciones: null,
        otros_accesorios: null,
        reporte_falla_cliente: null,
        guia_despacho_ingreso: null,
        numero_cotizacion: null,
        // tienda: null,
        sucursal: null,
        fecha_de_compra: null,
        numero_boleta: null,
        need3x3: false,
        despacho: null,
      },
      warrantyOptions: [
        { value: null, text: 'Seleccione' },
        { value: true, text: 'Si' },
        { value: false, text: 'No' },
      ],
      serviceOptions: [
        { value: null, text: 'Seleccione' },
        { value: 1, text: 'Garantía' },
        // { value: 2, text: 'Garantía on site 5D' },
        // { value: 3, text: 'Garantía on site 7x24' },
        { value: 4, text: 'Presupuesto' },
        // { value: 5, text: 'Instalación de partes' },
        // { value: 6, text: 'Garantía de reparación' },
      ],
      tipoFallaOptions: [
        {
          value: null,
          text: 'Seleccione',
          disabled: true,
        },
        {
          value: 1,
          text: 'Falla de Hardware',
        },
        {
          value: 2,
          text: 'Falla de Software',
        },
      ],
      optionsDespacho: [
        { value: null, text: 'Seleccione...' },
        { value: 'ENVIO', text: 'Envío' },
        { value: 'RETIRO', text: 'Retiro por el cliente' },
        { value: 'ON_SITE', text: 'On Site' },
      ],
    };
  },
  methods: {
    ...mapActions('OTStore', ['createOT', 'editOT', 'getOT', 'uploadFile', 'createStore', 'completPreIngreso', 'getFileUrl', 'getFiles']),
    ...mapActions('ClientStore', ['listEmpresas']),
    ...mapActions('Exceptions', ['createExcepcion', 'editExcepcion']),

    async setBoletaUrl() {
      await this.getFiles(this.ot.ot)
      const item = this.files?.find((f) => f.type === this.ARCHIVO_ADJUNTO_TIPO.BOLETA)
      if (!item) return null

      const payload = {
        fileName: item.name,
        fileType: item.file_type,
        fileId: item.file_id,
      }
      this.boletaUrl = await this.getFileUrl(payload)
      this.boletaType = item.file_type
      this.boletaName = item.name
    },
    async onSubmit() {
      this.loading = true;
      let resp = null
      if (this.$v.$invalid) {
        this.$bvToast.toast('Complete todo los campos requeridos', {
          variant: 'danger',
        });
        this.$v.$touch();
      } else {
        let tienda_empresa = null;
        if (this.client.tipo_cliente === 1 && (this.form.tipo_servicio === 1 || this.form.tipo_servicio === 2 || this.form.tipo_servicio === 3)) {
          tienda_empresa = this.form.sucursal;
        }
        const sucursal = this.sucursal ? this.sucursal.id || this.sucursal : null;
        const payload = {
          // controlados en otros componentes
          cliente: this.client.id,
          equipo: this.equipment.id,
          tienda_empresa,
          // controlados en este form
          ot: this.form.ot,
          rma: this.form.rma,
          codigo_retail: this.form.codigo_retail,
          tipo_servicio: this.form.tipo_servicio,
          tipo_falla: this.form.tipo_falla,
          observaciones: this.form.observaciones,
          otros_accesorios: this.form.otros_accesorios,
          reporte_falla_cliente: this.form.reporte_falla_cliente,
          guia_despacho_ingreso: this.form.guia_despacho_ingreso,
          fecha_de_compra: this.form.fecha_de_compra,
          numero_boleta: this.form.numero_boleta,
          // tienda: this.form.tienda ? this.form.tienda.id : null,
          tienda: null,
          numero_cotizacion: this.form.numero_cotizacion,
          exige_cambio_3x3: this.form.need3x3,
          despacho: this.form.despacho,
        };
        if (this.ot.estado && (this.ot.estado?.estado?.nombre === 'Pre ingreso' || this.ot.estado?.estado?.nombre === 'Pre ingreso regiones')) {
          try {
            payload.sucursal_id = sucursal;
            resp = await this.completPreIngreso(payload);
            this.$bvToast.toast('Pre ingreso completado exitosamente', {
              variant: 'success',
            });
            this.$emit('changed');
            this.$router.push(`/orden/${this.ot.ot}`);
          } catch (err) {
            this.$bvToast.toast(`Error al completar pre ingreso: ${err}`, {
              variant: 'danger',
            });
          }
        } else {
          payload.sucursal = sucursal;
          if (this.isCreatedOT) {
            this.creating = true;
            if (payload.tipo_servicio === 4 || payload.tipo_servicio === 5 || payload.tipo_servicio === 6) {
              payload.fecha_de_compra = null;
              payload.tienda = null;
            }
            if (payload.tipo_servicio !== 4) {
              payload.numero_cotizacion = null;
            }
            resp = await this.editOT(payload);
            this.creating = false;
            if (this.statusRequest) {
              if (this.statusRequest.status === 200 || this.statusRequest.status === 201) {
                this.setOtForm(this.statusRequest.data);
                this.$bvToast.toast(`Orden de Trabajo editada exitosamente${this.statusRequest.data.ot}`, {
                  variant: 'success',
                });
                // this.uploadFotos();
                this.$refs.upload.uploadNewFiles();
                this.$router.push(`/orden/${this.statusRequest.data.ot}`);
              } if (this.statusRequest.status === 400) {
                this.$bvToast.toast(`Error al guardar: ${this.statusRequest.data}`, {
                  variant: 'danger',
                });
              }
            }
          } else {
            await this.createOT(payload);
            this.creating = false;
            if (this.statusRequest) {
              if (this.statusRequest.status === 200 || this.statusRequest.status === 201) {
                await this.getOT(this.statusRequest.data.ot);
                // verificar excepcion ampliacion de garantia
                const tipos_de_servicio = [1, 2, 3]; // Garantía, Garantía on Site 5D , Garantía on Site 7x24
                if (tipos_de_servicio.includes(this.ot.tipo_servicio.id) === true) {
                  this.createExceptionWarrantyExtension();
                }
                // this.uploadFotos();
                this.$refs.upload.uploadNewFiles();
                this.setOtForm(this.statusRequest);
                this.$bvToast.toast(`Orden de Trabajo guardada exitosamente: ${this.statusRequest.ot}`, {
                  variant: 'success',
                });
                this.$router.push(`/orden/${this.statusRequest.data.ot}`);
              } else {
                this.$bvToast.toast(`Error al guardar: ${this.statusRequest.data}`, {
                  variant: 'danger',
                });
              }
            }
          }
        }
      }
      this.loading = false;
      this.collapseOT = true;
      console.log(resp)
    },
    async createExceptionWarrantyExtension() {
      const yearsWarranty = this.equipment.modelo_detail.years_warranty;
      const now = moment();
      const garantia = moment(this.ot.fecha_de_compra).add(yearsWarranty * 365, 'days');
      if (now.isAfter(garantia)) {
        const form = {
          estado: 1,
          orden_trabajo: this.ot.ot,
          comentario_solicitud: 'Solicita ampliación de garantía',
          solicitado_por: this.ot.estado.user.id,
          tipo: 'Ampliación de garantía',
        };
        const resp = await this.createExcepcion(form);
        if (resp) {
          const modalId = 'modalId';
          const ok = () => this.$bvModal.hide(modalId);
          let comment;
          await this.$bvModal.msgBoxConfirm(
          <div>
            <p>Se creo excepción con Ampliación de Garantía para la OT {this.ot.ot}</p>
            <label>
              Ingresa comentario:
              <textarea name="postContent" rows={3} cols={50} vModel={comment} vOn:keyup_enter={ok}/>
            </label>
          </div>, {
            id: modalId,
            title: 'Orden de trabajo con Excepción',
            size: 'md',
            okVariant: 'success',
            centered: true,
            noCloseOnBackdrop: true,
          },
          );
          form.id = resp.data.id;
          form.comentario_solicitud += `: ${comment}`;
          const response = await this.editExcepcion(form);
          console.log(response);
        }
      }
    },
    async submitFile() {
      if (!this.checkFiletypeForUpload(this.uploadingFile.type)) {
        this.$bvToast.toast('Solo se permiten archivos .jpg, .png, .pdf', { variant: 'danger' });
        return;
      }
      const formData = new FormData();
      formData.append('archivo', this.uploadingFile);
      if (this.statusRequest.data.ot) {
        formData.append('orden_trabajo', this.statusRequest.data.ot);
      }
      await this.uploadFile(formData);
    },
    checkFiletypeForUpload(fileType) {
      return ['image/jpeg', 'image/png', 'application/pdf'].includes(fileType);
    },
    async setOtForm(ot) {
      if (ot) {
        this.form = {
          ...ot,
          tipo_falla: typeof (ot.tipo_falla) === 'object'
            ? ot.tipo_falla.id
            : ot.tipo_falla,
          tipo_servicio: typeof (ot.tipo_servicio) === 'object'
            ? ot.tipo_servicio.id
            : ot.tipo_servicio,
          sucursal: ot.tienda_empresa?.id
        }
        this.setBoletaUrl()
        // this.$nextTick(() => {
        //   this.$refs.tiendaInput.inputValue = this.form?.tienda?.nombre;
        // });
      }
    },
    documentCollapse(collapse) {
      this.collapseDocuments = collapse;
    },
    handleNewTienda(empresaId) {
      this.listEmpresas()
      this.$nextTick(() => {
        this.form.sucursal = empresaId;
      })
    },
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'statusRequest', 'files']),
    ...mapGetters('ClientStore', ['client', 'sucursal', 'empresas']),
    ...mapGetters('EquipmentStore', ['equipment']),
    ...mapGetters('AuthenticationStore', ['user']),
    ARCHIVO_ADJUNTO_TIPO: () => ARCHIVO_ADJUNTO_TIPO,

    isCreatedOT() {
      return this.ot && this.ot.ot;
    },
    device() {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    // filelistFotos() {
    //   if (this.fotos) {
    //     return this.fotos.map((f, index) => ({
    //       index,
    //       file: index + 1,
    //       preview: f,
    //     }));
    //   }
    //   return [];
    // },
  },
  watch: {
    ot() {
      if (this.ot) this.setOtForm(this.ot);
    },
    camera(id) {
      this.deviceId = id;
    },
    devices() {
      const [first, ...tail] = this.devices; //eslint-disable-line
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
    'form.tipo_servicio': function fn() {
      if (this.form.tipo_servicio === 1 || this.form.tipo_servicio === 2 || this.form.tipo_servicio === 3 || this.form.tipo_servicio === 4) {
        this.collapseDocuments = true;
      }
    },
  },
  created() {
    this.listEmpresas()
    if (this.ot && this.ot.ot) {
      this.setOtForm(this.ot)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.client-header{
  color: #253590;
  font-weight: bold;
}
.client-form-inputs{
  color: #74788D;
}
.preview, .capture {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
img{
  width: 100%;
}
.thumbnail {
  height: 60px;
  width: auto;
}
.device-image {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
.device-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
</style>
