<template lang="pug">
div
  //- Agregar cotización external
  Navbar
  .container-md
    h4.main-header.mb-3 Solicitud de Oportunidad de Venta
    //- b-link( @click="cancelar()" ).cancelar Cancelar

    b-card.form-card
      b-row.steps.mb-4
        b-col
          .flex-row.align-items-center
            span.circle-icon.text-title {{ '1' }}
              //- b-icon(v-if="step > 1" icon="check" font-scale="2.7")
            h2.m-0.text-title(:style="'color: #566573'") Datos del Cliente
        b-col
          .flex-row.align-items-center
            span(:class="circleClassEquip") {{ '2' }}
              //- b-icon(v-if="step > 2" icon="check" font-scale="2.7")
            h2.m-0(:style="titleEquip") Datos del Equipo
        b-col
          .flex-row.align-items-center
            span(:class="circleClassParts") {{ '3' }}
              //- b-icon(v-if="step > 3" icon="check" font-scale="2.7")
            h2.m-0(:style="titleParts") Repuestos
        b-col
          .flex-row.align-items-center
            span(:class="circleClassResumen") {{ '4' }}
              //- b-icon(v-if="step > 4" icon="check" font-scale="2.7")
            h2.m-0(:style="titleResumen") Resumen

      DataClientQuotations(
        :setDataClient="DataClient"
        :returnClient="dataClient"
        v-if="step === 1"
      )
      DataEquipQuotations(
        :setFormEquip="DataEquip"
        :returnSerialImg="setSerialImg"
        :returnSerialNumber="formEquip"
        v-show="step === 2"
        @goBack = "getBack"
      )
      DataPartsQuotations(
        v-show="step === 3"
        :setDataParts = "DataParts"
        @goBack = "getBack"
      )
      ResumeQuotations(
        v-if="step === 4"
        :dataClient="dataClient"
        :form="form"
        @goBack = "getBack"
        @created = "handleCreated"
      )
</template>

<script>
import Navbar from '@/components/OT/ClientOT/Navbar/Navbar.vue'
import DataClientQuotations from './FormExternalQuotations/DataClientQuotations.vue'
import DataEquipQuotations from './FormExternalQuotations/DataEquipQuotations.vue'
import DataPartsQuotations from './FormExternalQuotations/DataPartsQuotations.vue'
import ResumeQuotations from './FormExternalQuotations/ResumeQuotations.vue'

export default {
  components: {
    DataClientQuotations,
    DataEquipQuotations,
    DataPartsQuotations,
    ResumeQuotations,
    Navbar,
  },
  data() {
    return {
      step: 1,
      dataClient: {
        nombre: null,
        apellido: null,
        rut: null,
        email: null,
        comuna: null,
        telefono: null,
        direccion: null,
        region: null,
      },
      formEquip: {
        serial_number: null,
      },
      serialImg: null,
      parts: [
        {
          partName: null,
          partDescriptions: null,
          file: null,
        },
      ],
    }
  },
  methods: {
    DataClient(dataClient) {
      console.log(this.dataClient)
      this.dataClient = dataClient
      this.step += 1
    },
    DataEquip(formEquip) {
      console.log(formEquip)
      this.formEquip.serial_number = formEquip
      this.step += 1
    },
    DataParts(parts) {
      console.log(parts)
      this.step += 1
      this.parts = parts
    },
    setSerialImg(serialImg) {
      this.serialImg = serialImg
    },
    getBack() {
      this.step -= 1
    },
    cancel() {
      this.formClient = {
        dataClient: {
          rut: null,
          nombre: null,
          apellido: null,
          email: null,
          telefono: null,
          direccion: null,
          comuna: null,
          region: null,
        },
      }
      this.step = 1
    },
    handleCreated() {
      this.$router.go()
    },
  },
  computed: {
    titleEquip() {
      return this.step >= 2 ? 'color: #23201C' : 'color: #525252'
    },
    titleParts() {
      return this.step >= 3 ? 'color: #23201C' : 'color: #525252'
    },
    titleResumen() {
      return this.step >= 4 ? 'color: #23201C' : 'color: #525252'
    },
    circleClassEquip() {
      return this.step >= 2
        ? 'circle-icon'
        : 'circle-icon circle-disabled'
    },
    circleClassParts() {
      return this.step >= 3
        ? 'circle-icon'
        : 'circle-icon circle-disabled'
    },
    circleClassResumen() {
      return this.step >= 4
        ? 'circle-icon'
        : 'circle-icon circle-disabled'
    },
    form() {
      return {
        client: this.dataClient,
        device: this.formEquip,
        deviceImage: this.serialImg,
        parts: this.parts,
      }
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
@import '~@/style/cliente.scss';
@import '~@/style/colors.scss';

::v-deep.navbar {
  margin-bottom: 30px;
}
::v-deep.navbar-expand {
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep.form-card {
  box-shadow: 0 3px 6px 0 #00000029;
  opacity: 1;
  padding: 0;
}
.form-group::v-deep label {
  color: var(--info);

  &.active-label {
    color: $samtek-black;
  }
}
::v-deep .circle-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: $samtek-black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  background: $samtek-yellow 0% 0% no-repeat padding-box;
  border-radius: 50%;
}
.circle-disabled {
  background-color: #F5F6FA;
  color: $samtek-grey;
  font-weight: 700;
}
.steps h2 {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 680px) {
  .steps h2 {
    font-size: 16px;
    text-align: center;
  }
}

::v-deep .form-btn {
  width: 190px;
  height: 45px;
}

::v-deep .form-control::placeholder {
  color: #cbcbcb !important;
}
.text-title {
  color: $samtek-black !important;
  font-weight: 700;
}
</style>
