<template lang="pug">
    .wrapper
      b-dropdown(
          text ="Filtro"
          class="dropdown-filters float-right"
          right
        )
          b-dropdown-item(:class="{'dropdown-item-element': filters.tipoServicio === GARANTIA, 'dropdown-item-margin': true }"
                          :disabled="loading" @click="toggleServicio(GARANTIA)")
                          p(class="mt-2 mb-2 ml-0 ") Garantía
                            b-icon(icon="check-circle" v-show="filters.tipoServicio === GARANTIA" class="check-icon float-right")
          b-dropdown-item(:class="{'dropdown-item-element': filters.tipoServicio === PRESUPUESTO, 'dropdown-item-margin': true }" 
                          :disabled="loading"  @click="toggleServicio(PRESUPUESTO)")
                          p(class="mt-2 mb-2") Presupuesto
                            b-icon(icon="check-circle" v-show="filters.tipoServicio === PRESUPUESTO" class="check-icon ml-2 float-right")
          b-dropdown-item(:class="{'dropdown-item-element': filters.tipoCliente === PERSONA, 'dropdown-item-margin': true }" 
                          :disabled="loading"  @click="toggleCliente(PERSONA)")
                          p(class="mt-2 mb-2 d-flex justify-content-between align-items-center") Cliente persona
                            b-icon(icon="check-circle" v-show="filters.tipoCliente === PERSONA" class="check-icon ml-2 float-right")
          b-dropdown-item(:class="{'dropdown-item-element': filters.tipoCliente === EMPRESA, 'dropdown-item-margin': true }" 
                          :disabled="loading" @click="toggleCliente(EMPRESA)")
                          p(class="mt-2 mb-2 d-flex justify-content-between align-items-center") Cliente empresa
                            b-icon(icon="check-circle" v-show="filters.tipoCliente === EMPRESA" class=" check-icon ml-2 float-right")
          b-dropdown-item(:class="{'dropdown-item-element': [...filters.alertColor].includes(AMARILLA), 'dropdown-item-margin': true }" 
                          :disabled="loading" @click="toggleAlertColor(AMARILLA)")
                          p(class="mt-2 mb-2 d-flex justify-content-between align-items-center") Alerta Amarilla
                            b-icon(icon="check-circle" v-show="[...filters.alertColor].includes(AMARILLA)" class=" check-icon ml-1 float-right")
          b-dropdown-item(:class="{ 'dropdown-item-element': [...filters.alertColor].includes(ROJA), 'dropdown-item-margin': true }" 
                          :disabled="loading" @click="toggleAlertColor(ROJA)")
                          p(class="mt-2 mb-2 text-danger") Alerta Roja
                            b-icon(icon="check-circle" v-show="[...filters.alertColor].includes(ROJA)" class="check-icon float-right")
    
    </template>
    
    <script>
    import { mapGetters } from 'vuex';
    import { mapState, mapWritableState } from 'pinia'
    import { useDashboardStore } from '../../pinia/views/useDashboardStore'
    
    export default {
      name: 'ButtonsFilters',
    
      props: {
        showInDashboard: {
          type: Boolean,
          default: true,
        },
      },
    
      computed: {
        ...mapWritableState(useDashboardStore, ['filters']),
        ...mapState(useDashboardStore, ['loading']),
        ...mapGetters('OT', ['isSearching']),
    
        NOKBO: () => 2,
        STOP: () => 5,
        ALLOCATED: () => 3,
    
        GARANTIA: () => 1,
        PRESUPUESTO: () => 4,
        PERSONA: () => 1,
        EMPRESA: () => 2,
    
        AMARILLA: () => 'yellow',
        ROJA: () => 'red',
      },
    
      methods: {
        toggleServicio(servicio) {
          this.filters.tipoServicio =
            this.filters.tipoServicio === servicio ? null : servicio
        },
    
        toggleCliente(cliente) {
          this.filters.tipoCliente =
            this.filters.tipoCliente === cliente ? null : cliente
        },
        toggleAlertColor(color) {
          const i = this.filters.alertColor.indexOf(color)
          if (i === -1) {
            this.filters.alertColor = [...this.filters.alertColor, color]
          } else {
            this.filters.alertColor = this.filters.alertColor.filter((c) => c !== color)
          }
        },
        cleanFilters() {
          this.filters = {
            tipoServicio: null,
            tipoCliente: null,
            fase: null,
            alertColor: [],
          }
        },
      },
      mounted () {
        this.cleanFilters()
        if (this.showInDashboard) {
          this.filters.tipoServicio = this.GARANTIA
        }
      }
    }
    </script>
    
    <style lang="scss" scoped>
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';
    
    .wrapper {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }
    ::v-deep .btn-secondary,
    .btn-secondary:hover {
      color: #fff;
      font-weight: bold;
      background-color: #5b73e8;
      border-color: #5b73e8;
      opacity: 1;
    }
    ::v-deep .btn-warning,
    .btn-warning:hover {
      color: #202223;
      font-weight: bold;
      background-color: #f1b44c;
      border-color: #f1b44c;
      opacity: 0.8;
    }
    ::v-deep .btn-info,
    .btn-info:hover {
      color: #202223;
      font-weight: bold;
      background-color: #0096db;
      border-color: #0096db;
      opacity: 0.8;
    }
    ::v-deep .btn-success,
    .btn-success:hover {
      color: #202223;
      font-weight: bold;
      background-color: #34c38f;
      border-color: #34c38f;
      opacity: 0.9;
    }
    ::v-deep .dropdown-menu {
        border: 1px solid #525252;
    }
    .dropdown-item-margin{
      margin: 0px;
    }
    
    .dropdown-item-element {
      background-color: #D5DCFF;
    
    }
    ::v-deep .dropdown-filters {
      width: 100%;
      display: none; 
    
      @include media-breakpoint-down(sm) {
        display: block;
    
        .dropdown-menu {
         
          min-width: 30px; // Ajusta el ancho mínimo del dropdown
        }
      }
    }
    ::v-deep .dropdown-toggle {
        width: 100%;
        background-color: #253590 !important;
        color: #fff;
        font-weight: bold;
        border-color: #0056b3;
        border: none !important;
        &:focus,
        &:active,
        &:hover {
            background-color: #253590 !important;
            border-color: #253590 !important;
            color: #fff;
            outline: none; 
            box-shadow: none !important;
        }
    }
    .text-alerta {
        color: #FFC109;

    }
    .check-icon {
        color: #5B73E8 ;
        height: 20px;
        width: 20px;
    }
  
    
    </style>
    